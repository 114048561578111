import { useContext, useEffect, useState } from 'react';
import { Switch, Route, useHistory, useLocation } from 'react-router-dom';
import NotFound from './NotFound';
import SideNav from '../base/components/SideNav';
import Responsive from 'context/responsive';
import routes from './routes';
import {Row, Col, Container} from '@bootstrap-styled/v4';
import Lead from 'pages/Lead';
import { bool } from 'prop-types';

export default function RouterConfig({hidden}) {
  const allRoutes = [
    ...routes.sideNav,
    ...routes.main,
    ...routes.leadNav,
    ...routes.companyNav,
  ];

  const ctx = useContext(Responsive);
  const [id, setId] = useState();
  const history = useHistory();
  let location = useLocation();
  const home = location.pathname === '/dashboard' || location.pathname === '/';

  useEffect(() => {
    if(!home) {
      ctx.setDetails(false);
    }
  }, [location, home, ctx]);

  useEffect(() => {
    ctx.setNavbar(false);
    /* eslint-disable */
  }, [location]);
  /* eslint-enable */

  useEffect(() => {
    if(location.pathname.includes('/leads/') && !id){
      setId(parseInt(location.pathname.match(/\d+/)));
    }
    if(ctx?.active == false) {
      history.replace("/account");
    }
    /* eslint-disable */
  }, [location.pathname, ctx.active]);
    /* eslint-enable */

  return (
    <Container fluid className={ctx.isImpersonate ? 'impersonated' : ''}>
      <Row>
        {!ctx.isMobile && (
          <Col md="2" lg="1" className={ctx.details ? 'detailed' : null}>
            <SideNav 
              routes={routes.sideNav}
              leadRoutes={routes.leadNav}
              companyRoutes={routes.companyNav}
            />
          </Col>
        )}
        <Col md="10" col='12' lg="11" className={hidden ? 'hiddenY relative main' : 'relative main'}>
          <Switch>
            {/* List all routes here */}
            {allRoutes.map((route, index) => (
              <Route
                key={`${index}-${route.name}`}
                path={route.path}
                exact={route.exact}
                component={ctx.role == 'manager' || ctx.role == 'user' ? route.manager : route.main}
              ></Route>
            ))}
            <Route path={'/leads/:id'} >
              {id && <Lead number={id} setId={setId} />}
            </Route>
            {/* List a generic 404-Not Found route here */}
            <Route path="*">
              <NotFound />
            </Route>
          </Switch>
        </Col>
      </Row>
    </Container>
  );
}

RouterConfig.propTypes = {
  hidden: bool
};
