import { useState, useRef, useContext } from 'react';
import { array, func } from 'prop-types';
import { useTranslation } from 'react-i18next';
import styled from 'styled-components';
import { ReactComponent as Close } from 'assets/images/close.svg';
import { ReactComponent as Arrow } from 'assets/images/arrow.svg';
import {
  Block,
  FlexWrapper,
  ModalFull,
  SecondaryBtnHigh
} from 'base/styled';
import TooltipDark from 'base/components/TooltipDark';
import TableLeadQueue from 'base/components/Leads/NewQueue/TableLeadQueue';
import LeadQueueForm from './LeadQueueForm';
import Responsive from 'context/responsive';

const Button = styled.a`
  cursor: pointer;
`;

const BlockFilter = styled(Block)`
  margin: 20px 0;
  padding: 20px;
`;


const Title = styled.h3`
  position: relative;
  margin: 0;
  margin-right: 360px;
  white-space: nowrap;
  .tooltip {
    position: absolute;
    left: auto;
    right: -10px;
    top: 0;
    span {
      top: -28px;
    }
  }
  @media (max-width: 768px) {
    margin-right: 0;
    font-size: 1.25rem
  }
`;

const FlexWrapperCenter = styled(FlexWrapper)`
  align-items: center;
`;


const CloseBtn = styled.div`
  position: absolute;
  bottom: 10px;
  left: 49%
  svg {
    transform: rotate(-90deg);
  }
`;

export default function LeadQueue({handleOpen, queues, gic,
  naics}) {

  const { t: homeT } = useTranslation('home');
  const [filterUserEdit, setFilterUserEdit] = useState();
  const [filterUser, setFilterUser] = useState(false);
  const [edit, setEdit] = useState(false);
  const ctx = useContext(Responsive);

  function handleLeadForm() {
    setFilterUser(true);
  }
 
  function handleLeadFormOff() {
    setFilterUser();
    setFilterUserEdit();
    setEdit(false);
  }
  
  function handleFilterUser(id) {
    if (edit) {
      setFilterUserEdit(id);
      setFilterUser(true);
      setEdit(true);
      
    } else {
      setFilterUser(true);
      setFilterUserEdit();
      setEdit();
    }
  }

  const myRef = useRef(null);
  const executeScroll = () => myRef.current.scrollIntoView();
 
  return (
    <ModalFull>
      <FlexWrapperCenter ref={myRef}>
        <Button>
          <Close onClick={handleOpen} />
        </Button>
        <Title>
          {homeT('leadQueues')}
          <TooltipDark text={homeT('addQueueTooltip')} className="tooltip" />
        </Title>
        {!filterUser && <SecondaryBtnHigh onClick={handleLeadForm}>
          {homeT('addMore')}
        </SecondaryBtnHigh>}
      </FlexWrapperCenter>
      {filterUser && (
        <BlockFilter>
          <LeadQueueForm
            handle={handleLeadFormOff}
            filterUserEdit={filterUserEdit}
            edit={edit}
            gic={gic}
            naics={naics}
          />
        </BlockFilter>
      )}
      <TableLeadQueue
        handleFilterUser={handleFilterUser}
        setEdit={setEdit}
        setFilterUserEdit={setFilterUserEdit}
        list={queues.filter(e => e.id !== -2)}
        gic={gic}
        edit={edit}
        naics={naics}
        executeScroll={executeScroll}
      />
      {ctx.isMobile && <CloseBtn onClick={handleOpen}>
        <Arrow />
      </CloseBtn>}
    </ModalFull>
  );
}

LeadQueue.propTypes = {
  handleOpen: func,
  queues: array,
  gic: array,
  naics: array,
};
