import React from "react";
import Error from "./Error";
import network from "base/network";

class ErrorBoundary extends React.Component {
  constructor(props) {
    super(props);
    this.state = { hasError: false };
  }

  static getDerivedStateFromError() {
    return { hasError: true };
  }

  componentDidCatch(error, errorInfo) {
    console.log(error.toString());
    console.log(JSON.stringify(errorInfo));
    network
      .post('/api/error_report', {
        message:error.toString(), 
        backtrace:JSON.stringify(errorInfo),
        page_path: window.location.pathname
      })
      .then(({ data }) => {
        return data;
      })
      .catch((error) => console.log(error));
  }
  
  render() {
    if (this.state.hasError) {
      return <Error />;
    }
    return this.props.children;
  }
}

export default ErrorBoundary;