import { useContext, useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useTranslation } from 'react-i18next';
import { Sidebar, MenuItem, List, InlineLink, Block, Modal, Overflow } from 'base/styled';
import { useHistory, useLocation } from 'react-router-dom';
import {selectRouteByKey} from 'navigation/routes.js';
import styled from 'styled-components';
import { arrayOf, shape, bool, string, elementType } from 'prop-types';
import { ReactComponent as Arrow } from 'assets/images/arrow.svg';
import img from 'assets/images/angle_left.svg';
import Responsive from 'context/responsive';
import RequestPaid from 'pages/Personalize/RequestPaid';
import { fetchAllDetails } from 'store/slices/account';

const StyledLink = styled(InlineLink)`
  color: ${props => props.theme.colors.black};
  font-weight: ${props => props?.active ? '600' : '400'};
  display: flex;
  justify-content: space-between;
  align-items: center;
  &:hover, .active {
    color: ${props => props.theme.colors.accentRed};
    svg {
      fill: ${props => props.theme.colors.accentRed}; 
      &.bench path {
        stroke: ${props => props.theme.colors.accentRed};
      }
    }
  }
  .active { 
    svg {
      width: auto;
    }
  }
  svg {
    width: 28px;
    fill: ${props => props?.active ? props.theme.colors.accentRed : props.theme.colors.black};
    &.bench path {
      stroke: ${props => props?.active ? props.theme.colors.accentRed : props.theme.colors.black};
    }
  }
`;

const ListNav = styled(List)`
  position: relative;
  mmin-width: 130px;
  .leads,
  .company {
    position: absolute;
    right: -240px;
    display: none;
  }
  .leads {
    top: -11px;
  }
  .company {
    top: 79%;
    right: -236px;
  }
  .leadsItem {
    &:hover {
      .leads {
        display: block;
      }
    }
  }
  .companyItem {
    &:hover {
      .company {
        display: block;
      }
    }
  }
  .beta {
    margin-top: 20px;
  }
  @media (max-width: 768px) {
    .leads {
      top: 39px;
      right: auto;
      left: 13px;
      background: #fff;
      width: 233px;
    }
    .company {
      top: 277px;
      right: auto;
      left: 25px;
      background: #fff;
      width: 233px;
    }
    svg.arrow {
      margin-left: 0;
    }
    a {
      padding-left: 0px;
    }
    .leadsItem, .companyItem {
      a {
        position: absolute;
        z-index: -2;
        background: url(${img}) no-repeat 10% center
      }
    .leads, .company {
      li {
        width: fit-content;
        a {
          position: static;
          z-index: auto;
          background: inherit;
        }
      }
      
    }
    }
  }
`;

const AccentBlock = styled(Block)`
box-shadow: 4px 12px 32px 18px rgba(238, 62, 38, 0.06);
`;

const LeadMenuItem = styled(MenuItem)`
background-color: ${ props => props?.active ? props => props.theme.colors.bgAccent : 'transparent'};
padding: 15px 10px 15px 15px;
border-radius: 8px;
white-space: nowrap
svg:not(.arrow) {
  fill: ${props => props?.active ? 'white' : props.theme.colors.black};
  margin: 0 auto;
}
div {
  background:  ${ props => props?.active ? props => props.theme.colors.accentRed : 'transparent'}; 
  display: inline-grid;
      margin: auto;
      align-items: center;
      margin-right: 15px
}
p {
  min-width: 100px;
}
.arrow {
  fill: ${ props => props?.active ? props => props.theme.colors.accentRed : 'transparent'};
  margin-left: 20px;
}
`;

const Icon = styled.div`
  width: 28px;
  height: 28px;
  border-radius: 50%;
  padding: 3px;
  margin: 0 20px 0 5px;
  background: transparent;
`;

const Popup = styled(Block)`
  margin: -2% auto 0;
  padding: 20px;
  @media (max-width: 480px) {
    width: 96%;
    .success {
      margin: 0 auto;
    }
  }
`;

export default function SideNav({ routes, leadRoutes, companyRoutes, openUser }) {
  const { t } = useTranslation('sidenav');
  const [show, setShow] = useState(false);
  const { pathname } = useLocation();
  const isActivePath = (currentPath, routerPath) => currentPath == routerPath;
  const isActiveMenu = (currentPath) => {
    const leadRoutes = selectRouteByKey('leadsNav');
    if (leadRoutes.includes(currentPath)) {
      return true;
    }
    return false;
  };
  const ctx = useContext(Responsive);

  const dispatch = useDispatch();

  let location = useLocation();
  const history = useHistory();

  const { details, subscription } = useSelector(
    (state) => state.accountReducer
  );

  useEffect(() => {
    if(details?.length === 0) {
      dispatch(fetchAllDetails({ details }));
    }  
  }, [dispatch, details]);

  useEffect(() => {
    
    if(details?.trial_end_date == null) {
      ctx.setIsTrial(details?.trial_end_date);
    } 
    if(subscription?.name == 'Free lite plan') {
      ctx.setIsLiteFree(true);
    }
    if(details?.available_leads_count <= 0) {
      ctx.setHitLimit(details.lead_reset_date);
    }
    /* eslint-disable */
  }, [dispatch, details]);
  /* eslint-enable */

  useEffect(() => {
    if(location.pathname.includes('/personalize') && details?.length !== 0 && 
    details?.settings?.allow_personalisation != '1'){
      history.replace("/company");
      setShow(true);
    } 
    /* eslint-disable */
  }, [location.pathname, details?.length]);
  /* eslint-enable */
  const handleShow = () => {
    if(details?.settings?.allow_personalisation == '0' || !details?.settings?.allow_personalisation) {
      setShow(true);
    } else {
      ctx.setShowNewConversion(false);
      return;
    }
  };

  const handleShowOff = () => {
    setShow(false);
  };

  let role = ctx.impersonate ? (ctx.impersonate.role === 'admin' && ctx.role === 'admin') : ctx.role === 'admin';

  return (
    <Sidebar className={ openUser ? "side mobile-side" : "side" }>
      {show && (
        <>
          <Modal>
            <Overflow onClick={handleShowOff}></Overflow>
            <Popup>
              <RequestPaid handleShowOff={handleShowOff} />
            </Popup>
          </Modal>
        </>
      )}
      <ListNav>
        {routes.map((route, index) =>
          role ? (
            <MenuItem
              key={`${index}-${route.name}`}
              active={isActiveMenu(pathname) ? true : null}
              className={route.className}
            >
              <StyledLink
                to={(
                  details?.settings?.allow_personalisation == '1'
                    ? route.path
                    : route.access
                )}
                active={isActivePath(pathname, route.path) ? 1 : 0}
                onClick={route.isPersonalization ? handleShow : null}
                style={{pointerEvents: details?.active == false ? 'none' : ''}}
              >
                {isActivePath(pathname, route.path) ? route.iconActive : route.icon }
                
                {!ctx.details && t(route.name)}
              </StyledLink>
              {route.leadsNav && (
                <div className="leads">
                  <AccentBlock>
                    <List>
                      {leadRoutes.map((route, index) => (
                        <LeadMenuItem
                          key={`${index}-${route.name}`}
                          active={isActivePath(pathname, route.path) ? 1 : 0}
                          className={route.className}
                        >
                          <StyledLink
                            to={route.path}
                            active={isActivePath(pathname, route.path) ? 1 : 0}
                            style={{pointerEvents: details?.active == false ? 'none' : ''}}
                          >
                            <Icon className={isActivePath ? 'active' : ''}>
                              {route.icon}
                            </Icon>
                            <p>{t(route.name)}</p>
                            
                            {isActivePath && <Arrow className="arrow" />}
                          </StyledLink>
                        </LeadMenuItem>
                      ))}
                    </List>
                  </AccentBlock>
                </div>
              )}
              {route.subNav && (
                <div className="company">
                  <AccentBlock>
                    <List>
                      {companyRoutes.map((route, index) => (
                        route.name != 'integration' &&
                        <LeadMenuItem
                          key={`${index}-${route.name}`}
                          active={isActivePath(pathname, route.path) ? 1 : 0}
                          className={route.className}
                        >
                          <StyledLink
                            to={route.path}
                            active={isActivePath(pathname, route.path) ? 1 : 0}
                            style={{pointerEvents: details?.active == false ? 'none' : ''}}
                          >
                            <Icon className={isActivePath ? 'active' : ''}>
                              {route.icon}
                            </Icon>

                            <p>{t(route.name)}</p>
                            {isActivePath && <Arrow className="arrow" />}
                          </StyledLink>
                        </LeadMenuItem>
                      ))}
                    </List>
                  </AccentBlock>
                </div>
              )}
            </MenuItem>
          ) : route.user ? (
            <MenuItem
              key={`${index}-${route.name}`}
              active={isActiveMenu(pathname) ? true : null}
              className={route.className}
            >
              <StyledLink
                to={
                  details?.settings?.allow_personalisation
                    ? route.path
                    : route.access
                }
                active={isActivePath(pathname, route.path) ? 1 : 0}
                onClick={route.isPersonalization ? handleShow : null}
                style={{pointerEvents: details?.active == false ? 'none' : ''}}
              >
                {route.icon}
                {!ctx.details && t(route.name)}
              </StyledLink>
              {route.leadsNav && (
                <div className="leads">
                  <AccentBlock>
                    <List>
                      {leadRoutes.map((route, index) =>
                        route.user ? (
                          <LeadMenuItem
                            key={`${index}-${route.name}`}
                            active={isActivePath(pathname, route.path) ? 1 : 0}
                            className={route.className}
                          >
                            <StyledLink
                              to={route.path}
                              active={
                                isActivePath(pathname, route.path) ? 1 : 0
                              }
                              style={{pointerEvents: details?.active == false ? 'none' : ''}}
                            >
                              <Icon className={isActivePath ? 'active' : ''}>
                                {route.icon}
                              </Icon>

                              {!ctx.details && t(route.name)}
                              {isActivePath && <Arrow className="arrow" />}
                            </StyledLink>
                          </LeadMenuItem>
                        ) : null
                      )}
                    </List>
                  </AccentBlock>
                </div>
              )}
              {route.subNav && (
                <div className="company">
                  <AccentBlock>
                    <List>
                      {companyRoutes.map((route, index) => (
                        <LeadMenuItem
                          key={`${index}-${route.name}`}
                          active={isActivePath(pathname, route.path) ? 1 : 0}
                          className={route.className}
                        >
                          <StyledLink
                            to={route.path}
                            active={isActivePath(pathname, route.path) ? 1 : 0}
                            style={{pointerEvents: details?.active == false ? 'none' : ''}}
                          >
                            <Icon className={isActivePath ? 'active' : ''}>
                              {route.icon}
                            </Icon>

                            {!ctx.details && t(route.name)}
                            {isActivePath && <Arrow className="arrow" />}
                          </StyledLink>
                        </LeadMenuItem>
                      ))}
                    </List>
                  </AccentBlock>
                </div>
              )}
            </MenuItem>
          ) : null
        )}
      </ListNav>     
    </Sidebar>
  );
}

SideNav.propTypes = {
  details: bool,
  routes: arrayOf(shape({
    name: string,
    path: string,
    exact: bool,
    main: elementType
  })),
  leadRoutes: arrayOf(shape({
    name: string,
    path: string,
    exact: bool,
    main: elementType
  })),
  companyRoutes: arrayOf(shape({
    name: string,
    path: string,
    exact: bool,
    main: elementType
  })),
  openUser: bool
};
