import { TextLightExtraSmall } from 'base/styled';
import { useTranslation } from 'react-i18next';
import styled from 'styled-components';

const BlockMargin = styled.div`
  margin: 0 auto;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  padding: 0px 26px;
  position: fixed;
  bottom: 10vh;
  left: -40px;
  z-index: -1;
  height: 2px;
  @media (max-width: 768px) {
    left: calc(36% - 10px);
    position: static;
    margin-top: 50px;
    margin-bottom: 10px
  }
`;

const Link = styled.a`
  color: grey;
  text-decoration: none;
  :hover {
    text-decoration: underline;
  }
  span {
    display: block;
  }
`;

const TermsText = styled (TextLightExtraSmall)`
  width: 110px;
  font-size: 6px;
  text-align: center;
`;

export default function Terms() {
  const { t: generalT } = useTranslation();
  const year = new Date().getFullYear();
  return (
    <BlockMargin>
      <TermsText>
        <Link href="https://www.visitorqueue.com/privacy" target="_blank">{generalT('policy')} 
          <span>{generalT('terms')}</span>
          <span>©{year}{generalT('vq')}</span> 
        </Link>
      </TermsText>
    </BlockMargin>
  );
}
