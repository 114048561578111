import { Block, TextBlack } from "base/styled";
import { useTranslation } from "react-i18next";
import { func } from "prop-types";
import styled from "styled-components";
import img from "assets/images/lightning-list.svg";
import after from "assets/images/after.svg";

const Wrapper = styled(Block)`
  position: absolute;
  top: -26px;
  right: 61px;
  max-width: 410px;
  flex-direction: column;
  padding: 20px 20px 20px;
  border-radius: 25px;
  z-index: 9;
  ul {
    list-style: none;
    font-size: 14px;
    padding: 0;
  }
  p {
    padding-left: 30px;
  }
  &:after {
    display: block;
    content: "";
    width: 24px;
    height: 90px;
    position: absolute;
    top: 17px;
    right: -23px;
    background: url(${after}) -88px -25px no-repeat;
    z-index: 0;
  }
`;

const Li = styled.li`
  padding: 15px 0 10px 30px;
  background: url(${img}) 0px 15px no-repeat;
`;

const Button = styled.button`
  color: #fff;
  background: linear-gradient(93.61deg, #2653f3 0.7%, #26a9f3 103.85%);
  border-radius: 50px;
  border: none;
  max-width: 100px;
  padding: 5px 25px;
  margin: 0 auto;
  cursor: pointer;
`;

export default function InfoBlock({ handleInfoOff }) {
  const { t: generalT } = useTranslation();

  return (
    <Wrapper>
      <TextBlack>
        {generalT("infoTitle")} <br />
        {generalT("infoSubtitle")}
      </TextBlack>
      <ul>
        <Li>{generalT("infoList_1")}</Li>
        <Li>{generalT("infoList_2")}</Li>
        <Li>{generalT("infoList_3")}</Li>
      </ul>
      <Button onClick={handleInfoOff}>{generalT("gotIt")}</Button>
    </Wrapper>
  );
}

InfoBlock.propTypes = {
  handleInfoOff: func,
};
