import { useState, useRef, useContext, useEffect } from 'react';
import { useDispatch } from 'react-redux';
import {
  Manage,
  ManageBox,
  TableFilters,
  TableTitle,
  Modal,
  Overflow,
  TextBlackSmall,
  TextGreyBold,
  TextBlack,
  Confirm,
  FlexEnd,
  Block,
  FilledButton,
  Overlay,
  GhostButton,
  Result,
  SecondaryBtnHigh
} from 'base/styled';
import { useTranslation } from 'react-i18next';
import { array } from 'prop-types';
import { ReactComponent as Copy } from 'assets/images/copy.svg';
import { ReactComponent as Mail } from 'assets/images/mail-sm.svg';
import { ReactComponent as Nav } from 'assets/images/navigation.svg';
import { ReactComponent as Edit } from 'assets/images/edit.svg';
import { ReactComponent as Delete } from 'assets/images/trash.svg';
import { ReactComponent as Advanced } from 'assets/images/advanced.svg';
import styled from 'styled-components';
import FormEmail from './FormEmail';
import Responsive from 'context/responsive';
import AddWebsite from 'base/components/Leads/Header/AddWebsite';
import { deleteWebsite, putWebsite } from 'store/slices/websites';
import { ReactComponent as Tooltip } from 'assets/images/tooltip.svg';
import TooltipContent from './TooltipContent';
import Integration from './Integration';

const Row = styled.div`
  display: flex;
  width: 100%;
  justify-content: space-between;
  p {
    font-size: 0.75rem;
    margin: 0 0 10px;
  }
  .title {
    width: 100%;
    .name {
      width: 55%;
    }
  }
  .value {
    display: flex;
    width: 100%;
    justify-content: space-between;
    p {
      padding: 5px 0;
    }
  }
`;

const Popup = styled(Block)`
  margin: -2% auto 0;
  padding: 20px 0;
  @media (max-width: 480px) {
    width: 96%;
    .success {
      margin: 0 auto;
    }
  }
`;

const ManageBoxRight = styled(ManageBox)`
  right: 18px;
  svg {
    min-width: 17px
  }
  a {
    text-decoration: none;
    color: inherit;
  }
  span {
    text-align: left;
    color: inherit;

  }
`;

const Detected = styled(TextGreyBold)`
  padding: 12px 0;
  width: fit-content;
  border-radius: 11.5px;
  color: #42AD31;
  font-weight: 400;
  font-size: 0.75rem;
  height: 25px;
`;

const Notdetected = styled(TextGreyBold)`
  padding: 12px 0;
  width: fit-content;
  border-radius: 11.5px;
  color: #EA3E2D;
  font-weight:400;
  font-size: 0.75rem;
  height: 25px;
  @media (max-width: 768px) {
    white-space: nowrap;
  }
`;

const Table = styled(TableFilters)`
  box-shadow: none;
  th:first-of-type {
    width: 15%;
  }
  td {
    vertical-align: top;
  }
  td:first-of-type,
  td:nth-child(2) {
    padding-top: 30px;
  }
`;

const Textarea = styled.textarea`
  border: 1px solid#D5F5FD;
  color: #85929b;
  background: #EFFCFF;
  border-radius: 8px;
  height: 80px;
  width: 64%;
  padding: 10px;
  @media (max-width: 768px) {
    width: 100%;
  }
`;

const Buttons = styled.div`
padding: 20px 0
  &:after {
    display: none!important
  }
  padding-top: 15px;
  button {
    padding: 10px 16px;
    height: auto;
    margin-right: 20px;
  }
  @media (max-width: 768px) {
    width: 100%;
    display: flex;
    button {
      height: 45px;
      white-space: nowrap;
    }
  }
`;

const TableNew = styled(Table)`
margin: 30px 0;
padding: 0 15px;
tr {
  &:after {
    width: 98%;
    left: 16px;
  }
  td:first-of-type {
    width: 50%;
  }
  td {
    z-index: auto;
  }
}
`;

const Button = styled(GhostButton)`
  border: none;
  outline: none;
  background: transparent;
  color: ${(props) => props.theme.colors.black};  
`;

const BoxBordered = styled.div`
  border-radius: 15px;
  background: #fff;
  width: 100%;
`;

const OverManage = styled(Overlay)`
z-index: 19;
background: inherit;
`;

const TooltipBox = styled.div`
   cursor: pointer;
   position: relative;
   top: -32px;
   right: -120px;
   height: 0;
   width: 0;
   .box {
    position: absolute;
    top: 25px;
    left: -200px;
    z-index: 3;
    border-radius: 10px;
    background: #FFF;
    box-shadow: -19px 4px 54px 0px rgba(94, 164, 194, 0.25);
    display: none;
   }
   &:hover {
      .box {
       display: block;
     }
   }
  `;

const Box = styled.div`
  padding: 18px;
  position:relative;
  &::after {
    content: '';
    display: block;
    position: absolute;
    left: 10px;
    bottom: 0;
    background: #ECEBEB;
    width: 95%;
    height: 1px;
  }
`;

const Step = styled.div`
  position: relative;
  margin: auto;
  margin-top: 80px;
  padding-bottom: 100px
  height: 100%;
  overflow-y: auto
  p.steps {
    text-align: center;
    margin-bottom: 40px
  }
`;

export default function WebsitesTable({ list }) {
  const [show, setShow] = useState(false);
  const [copied, setCopied] = useState(false);
  const [formEmail, setFormEmail] = useState(false);
  const [showModal, setShowModal] = useState(false);
  const [confirm, setConfirm] = useState(false);
  const [edit] = useState(true);
  const { t: homeT } = useTranslation('home');
  const { t: websiteT } = useTranslation('website');
  const [success, setSuccess] = useState();
  const [showStepThree, setShowStepThree] = useState(false);

  const textAreaRef = useRef(null);

  const dispatch = useDispatch();

  const handleFormEmail = (idx) => () => {
    setFormEmail((state) => ({
      ...state,
      [idx]: !formEmail[idx],
    }));
  };

  function handleFormEmailOff() {
    setFormEmail(false);
  }

  const handleShow = (idx) => () => {
    setShow((state) => ({
      ...state,
      [idx]: !show[idx],
    }));
  };

  function handleTextarea() {
    return;
  }

  const copyToClipboard = (idx, text) => () => {
    const ta = document.createElement('textarea');
    ta.innerText = text;
    document.body.appendChild(ta);
    ta.select();
    document.execCommand('copy');
    ta.remove();
    setCopied((state) => ({
      ...state,
      [idx]: !copied[idx],
    }));
    setSuccess('The script has been copied to your clipboard');
  };

  const handleShowModal = (idx) => () => {
    setShowModal((state) => ({
      ...state,
      [idx]: true
    }));
  };

  const handleShowStepThree = (idx) => () => {
    setShowStepThree((state) => ({
      ...state,
      [idx]: true
    }));
  };

  function handleShowOff() {
    setShowModal(false);
    setShow(false);
    setConfirm(false);
    setShowStepThree(false);
  }

  const handleShowDelete = (idx) => () => {
    setShowModal((state) => ({
      ...state,
      [idx]: true
    }));
    setConfirm(true);
  };

  const handleUpdate = (id, name) => () => {
    const data = {
      name: name
    };
    dispatch(putWebsite({id: id, body:data}));
    setShowModal(false);
    setShow(false);
    setConfirm(false);
  };

  function handleDelete(id) {
    dispatch(deleteWebsite(id)); 
    setSuccess('deleted');
  }

  useEffect(() => {   
    const timerIn = setTimeout(() => {
      if(success) {
        setSuccess(false);
      }
    }, 2200);
    return () => clearTimeout(timerIn);
  }, [success]);

  const ctx = useContext(Responsive);

  return (
    <>
      {success && (
        <>
          <Result>
            {success}
          </Result>
        </>
      )}
      {ctx.isMobile ? (
        <BoxBordered>
          {list &&
            list.map((lead, idx) => (
              <Box key={idx}>
                <Row>
                  <div className="title">
                    <TextBlack>{lead.name}</TextBlack>
                    <div className="value">
                      {lead.connected ? (
                        <Detected>{websiteT('detected')}</Detected>
                      ) : (
                        <Notdetected>{websiteT('notDetected')}</Notdetected>
                      )}
                    </div>
                  </div>
                  <Manage onClick={handleShow(idx)}>
                    <Nav />
                    {show[idx] && (
                      <>
                        <OverManage onClick={handleShowOff}></OverManage>
                        <ManageBoxRight>
                          <a onClick={handleShowModal(idx)}>
                            <Edit />
                            <span>{homeT('edit')}</span>
                          </a>
                          <a onClick={handleShowDelete(idx)}>
                            <Delete />
                            <span>{homeT('delete')}</span>
                          </a>
                          <a onClick={handleShowStepThree(idx)}>
                            <Advanced />
                            <span>{homeT('advanced')}</span>
                          </a>
                        </ManageBoxRight>
                      </>
                    )}
                  </Manage>
                </Row>
                <Row>
                  <div className="title">
                    <TextBlack>{homeT('tracking')}:</TextBlack>
                    <form>
                      <Textarea
                        ref={textAreaRef}
                        value={lead.tracking_script}
                        onChange={handleTextarea}
                      />
                    </form>
                    <Buttons>
                      <Button
                        onClick={copyToClipboard(idx, lead.tracking_script)}
                        disabled={copied[idx]}
                      ><Copy />
                        {!copied[idx] ? 'Copy script' : 'Copied!'}
                      </Button>
                      <Button onClick={handleFormEmail(idx)}>
                        <Mail />
                        {homeT('sendEmail')}
                      </Button>
                      {formEmail[idx] && (
                        <Modal>
                          <Overflow onClick={handleFormEmailOff}></Overflow>
                          <FormEmail
                            handleFormEmailOff={handleFormEmailOff}
                            code={lead.tracking_script}
                          />
                        </Modal>
                      )}
                    </Buttons>
                  </div>
                </Row>
                {showModal[idx] && (
                  <>
                    <Modal>
                      <Overflow onClick={handleShowOff}></Overflow>
                      {!confirm && (
                        <Popup>
                          <AddWebsite
                            item={lead}
                            handleOpenAddOff={handleShowOff}
                            edit={edit}
                            handleUpdate={handleUpdate}
                          />
                        </Popup>
                      )}
                      {confirm && (
                        <Confirm>
                          <TextBlack>{websiteT('delete')}</TextBlack>
                          <FlexEnd>
                            <SecondaryBtnHigh onClick={handleShowOff}>
                              {homeT('cancel')}
                            </SecondaryBtnHigh>
                            <FilledButton
                              /* eslint-disable */
                              onClick={() => handleDelete(lead.id)}
                              /* eslint-enable */
                            >
                              {homeT('delete')}
                            </FilledButton>
                          </FlexEnd>
                        </Confirm>
                      )}
                    </Modal>
                  </>
                )}
                {showStepThree[idx] && (
                  <Modal className='solid'>
                    <Step>
                      <Integration handleShowOff={handleShowOff} 
                        websites={true} 
                        website_id={lead.id}
                      />
                    </Step>
                    {console.log(lead)}
                  </Modal>
                )}
              </Box>
            ))}
        </BoxBordered>
      ) : (
        <TableNew>
          <tbody>
            <tr>
              <th>
                <TableTitle>{homeT('name')}</TableTitle>
              </th>
              <th>
                <TableTitle>{homeT('tracking')}</TableTitle>
                <TooltipBox>
                  <Tooltip />  
                  <TooltipContent />           
                </TooltipBox>               
              </th>
              <th>
                <TableTitle>{homeT('manage')}</TableTitle>
              </th>
            </tr>

            {list &&
              list.map((lead, idx) => (
                <tr key={idx}>
                  <td>
                    <TextBlackSmall> {lead.name}</TextBlackSmall>
                    {lead.connected ? (
                      <Detected>{websiteT('detected')}</Detected>
                    ) : (
                      <Notdetected>{websiteT('notDetected')}</Notdetected>
                    )}
                  </td>
                  <td>
                    <form>
                      <Textarea
                        ref={textAreaRef}
                        value={lead.tracking_script}
                        onChange={handleTextarea}
                      />
                    </form>
                    <Buttons>
                      <Button
                        onClick={copyToClipboard(idx, lead.tracking_script)}
                        disabled={copied[idx]}
                      ><Copy />
                        {'Copy script'}
                      </Button>
                      <Button onClick={handleFormEmail(idx)}>
                        <Mail />
                        {homeT('sendEmail')}
                      </Button>
                      {formEmail[idx] && (
                        <Modal>
                          <Overflow onClick={handleFormEmailOff}></Overflow>
                          <FormEmail
                            handleFormEmailOff={handleFormEmailOff}
                            code={lead.tracking_script}
                            id={lead.id}
                            setSuccess={setSuccess}
                          />
                        </Modal>
                      )}
                    </Buttons>
                  </td>
                  <td>
                    <Manage onClick={handleShow(idx)}>
                      <Nav />
                      {show[idx] && (
                        <>
                          <OverManage onClick={handleShowOff}></OverManage>
                          <ManageBoxRight>
                            <a onClick={handleShowModal(idx)}>
                              <Edit />
                              <span>{homeT('edit')}</span>
                            </a>
                            <a onClick={handleShowDelete(idx)}>
                              <Delete />
                              <span>{homeT('delete')}</span>
                            </a>
                            <a onClick={handleShowStepThree(idx)}>
                              <Advanced />
                              <span>{homeT('advanced')}</span>
                            </a>
                          </ManageBoxRight>
                        </>
                      )}
                    </Manage>
                  </td>
                  {showModal[idx] && (
                    <>
                      <Modal>
                        <Overflow onClick={handleShowOff}></Overflow>
                        {!confirm && (
                          <Popup>
                            <AddWebsite
                              item={lead}
                              handleOpenAddOff={handleShowOff}
                              edit={edit}
                              handleUpdate={handleUpdate}
                            />
                          </Popup>
                        )}
                        {confirm && (
                          <Confirm>
                            <TextBlack>{websiteT('delete')}</TextBlack>
                            <FlexEnd>
                              <SecondaryBtnHigh onClick={handleShowOff}>
                                {homeT('cancel')}
                              </SecondaryBtnHigh>
                              <FilledButton
                                /* eslint-disable */
                                onClick={() => handleDelete(lead.id)}
                                /* eslint-enable */
                              >
                                {homeT('delete')}
                              </FilledButton>
                            </FlexEnd>
                          </Confirm>
                        )}
                      </Modal>
                    </>
                  )}
                  {showStepThree[idx] && (
                    <Modal className='solid'>
                      <Step>
                        <Integration handleShowOff={handleShowOff} 
                          websites={true} 
                          website_id={lead.id}
                        />
                      </Step>
                      {console.log(lead)}
                    </Modal>
                  )}
                </tr>
             
              ))}
            
          </tbody>
        </TableNew>
      )}
    </>
  );
}

WebsitesTable.propTypes = {
  list: array,
};
