import { useState } from "react";
import { useTranslation } from "react-i18next";
import styled from "styled-components";
import { ReactComponent as Icon } from "assets/images/search_white.svg";
import img from "assets/images/beta_blue.svg";
import SearchInput from "./SearchInput";
import { number } from "prop-types";

const Block = styled.div`
  margin: 0 auto;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  padding: 8px 60px 8px 40px;
  position: fixed;
  bottom: 15px;
  left: 20px;
  z-index: 9;
  background: linear-gradient(93.61deg, #2653f3 0.7%, #26a9f3 103.85%);
  border-radius: 50px;
  @media (max-width: 768px) {
    left: auto;
    // position: static;
    margin-top: 90px;
  }
  &.fadein {
    -webkit-animation: display 0.3s linear forwards;
    animation: display 0.2s linear forwards;
  }
  
  @-webkit-keyframes display {
    0% {
      opacity: 0.2;
    }
    50% {
      opacity: 0.6;
    }
    100% {
      opacity: 1;
    }
  }
  
  @keyframes display {
    0% {
      opacity: 0.2;
    }
    50% {
      opacity: 0.6;
    }
    100% {
      opacity: 1;
    }
  }
`;

const Link = styled.div`
  color: inherit;
  text-decoration: none;
  :hover {
    text-decoration: none;
  }
`;

const Text = styled.div`
  font-weight: 600;
  font-size: 11px;
  line-height: 25px;
  color: #ffffff;
  text-align: center;
  position: relative;
  cursor: pointer;
  &:after {
    display: block;
    content: "";
    position: absolute;
    top: 6px;
    right: -46px;
    background: url(${img}) no-repeat 10% center;
    width: 39px;
    height: 13px;
  }
  svg {
    position: absolute;
    top: 6px;
    left: -25px;
  }
`;

export default function SmartSearch({id}) {
  const { t: generalT } = useTranslation();
  const [searchState, setSearchState] = useState({
    isOpen: false,
    fade: false
  });
  
  function handleSearchOpen() {
    setSearchState({
      isOpen: true,
      fade: true
    });
  }
  

  function handleSearchOpenOff() {
    setSearchState(prevState => ({
      ...prevState,
      fade: false
    }));
    setTimeout(() => { 
      setSearchState(prevState => ({
        ...prevState,
        isOpen: false
      }));
    }, 300);  
  }

  return (
    <>
      {searchState.isOpen ? (
        <SearchInput searchOpen={searchState.fade} handleSearchOpenOff={handleSearchOpenOff} id={id} />
      ) : (
        <Block className="fadein">
          <Text onClick={handleSearchOpen}>
            <Icon />
            <Link>{generalT("search")}</Link>
          </Text>
        </Block>
      )}
    </>
  );
}

SmartSearch.propTypes = {
  id: number
};
