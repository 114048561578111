import { Block, Flex, TextAccent, TextBlack, TextBlackThin } from "base/styled";
import styled from "styled-components";
import { ReactComponent as Spin } from "assets/images/spin-search.svg";
import { any, bool, string } from "prop-types";

const Wrapper = styled(Block)`
  margin-top: 12px;
  flex-direction: column;
  max-height: 300px;
  max-width: 70vw;
  overflow-y: scroll;
  border-radius: 25px;
  p {
    line-height: 17px;
    margin-left: 20px;
  }
  .ml {
    margin-left: 10px;
  }
  &.sending {
    min-height: 120px;
  }
`;

const Response = styled(Flex)`
  background: #eff9ff;
  border-radius: 20px;
  &.flex-column {
    display: flex;
    flex-direction: column;
  }
  .span {
    font-weight: 400;
  }
`;

const Table = styled.table`
  padding: 10px;
  width: 100%;
  border-collapse: collapse;
  thead tr {
    background: #eff9ff;
    td {
    font-weight: 500;
    font-size: 14px;
    line-height: 17px;
    color: #85929b;
    text-align: left;
    background: #eff9ff;
  }
}
  tbody tr:nth-child(odd) {
    background: white;
    td {
      background: white;
    }
  }
  tr {
    td,
    th {
      padding: 6px 0 6px 10px;
      text-transform: capitalize;
    }
    td:first-child {
      width: 70%;
    }
  }
`;

const Spinner = styled.div`
  animation: spin-animation 0.7s infinite;
  display: inline-block;
  width: 19px;
  height: 18px;
  margin: 0 auto;
  margin-top: 15px;
  @keyframes spin-animation {
    0% {
      transform: rotate(0deg);
    }
    100% {
      transform: rotate(359deg);
    }
  }
`;

export default function Dialog({ text, sending, result, keys, values }) {

  function formatDate(string) {
    const date = new Date(string);
  
    if (isNaN(date.getTime()) || string == null) {
      return string;
    }
    const options = { year: 'numeric', month: 'long', day: 'numeric' };
  
    const formattedDate = date.toLocaleDateString(undefined, options);
  
    return formattedDate;
  }
  
  return (
    <Wrapper className={sending ? 'sending' : ''}>
      <Flex>
        <TextBlack>You:</TextBlack>
        <TextBlackThin className="ml">{text}</TextBlackThin>
      </Flex>
      {sending ? (
        <Spinner>
          <Spin />
        </Spinner>
       
      ) : (
        <Response className={result?.type === 'message' ? "" : "flex-column"}>
          <>
            {result?.type === 'message' ? (<>
              <TextBlack>VQ:</TextBlack>
              <TextBlackThin className="ml">{result?.result}</TextBlackThin>
            </>
            ) : result?.type === 'table' ? (
              <>
                <TextBlack>VQ:</TextBlack>
                <Table>   
                  <thead>
                    <tr>
                      <td>{typeof keys[0] == 'string' ? keys[0].split('_').join(' ') : keys[0]}</td>
                      <td>{typeof keys[1] == 'string' ? keys[1].split('_').join(' ') : keys[1]}</td>
                    </tr>
                  </thead>
                  <tbody>
                    {values?.map((e, idx) => (
                      <tr key={idx}>
                        <td>{formatDate(e[0])}</td>
                        <td>{e[1]}</td>
                      </tr>
                    ) )}
                  </tbody>
                </Table>
              </>
            ) : (
              <>
                <TextAccent>VQ: <span className="span">{formatDate(result?.result)}</span></TextAccent>
              </>
            )}
          </>
        </Response>
      )}
    </Wrapper>
  );
}

Dialog.propTypes = {
  text: string, 
  string: bool, 
  sending: bool,
  result: any, 
  keys: any, 
  values: any
};
