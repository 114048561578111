import { useEffect, useState } from "react";
import { Flex, Overlay, TextAccentSmall } from "base/styled";
import styled from "styled-components";
import { ReactComponent as Logo } from "assets/images/logo_search.svg";
import { ReactComponent as SearchIcon } from "assets/images/search_blue.svg";
import { ReactComponent as Beta } from "assets/images/beta_big.svg";
import { ReactComponent as CloseIcon } from "assets/images/close_search.svg";
import { ReactComponent as SubmitIcon } from "assets/images/lightning.svg";
import { ReactComponent as InfoIcon } from "assets/images/question.svg";
import { ReactComponent as DeleteIcon } from "assets/images/close.svg";
import { useTranslation } from "react-i18next";
import FormTextField from "../FormTextField";
import InfoBlock from "./InfoBlock";
import Dialog from "./Dialog";
import network from "base/network";
import { bool, func, number } from "prop-types";

const Wrapper = styled.div`
  position: fixed;
  top: 35vh;
  transition: opacity 0.5s;
  opacity: 1;
  transition: all 250ms linear 2s;
  @media (max-width: 798px) {
    min-width: 90%;
  }
`;

const FlexLogo = styled(Flex)`
  position: relative;
  justify-content: center;
  width: 100%;
  padding: 20px 0;
  @media (max-width: 798px) {
    flex-direction: column;
    align-items: center;
  }
`;

const Block = styled.div`
  position: absolute;
  right: 17px;
  top: 2px;
  @media (max-width: 798px) {
    position: relative;
    right: -13px;
  }
`;

const Text = styled.div`
  font-weight: 600;
  font-size: 14px;
  line-height: 25px;
  color: #2599f3;
  text-align: center;
  display: flex;
  padding-top: 5px;
  .icon {
    position: absolute;
    top: 24px;
    left: -30px;
  }
  .beta {
    margin: 15px 0 0 8px;
  }
`;

const Form = styled.form`
  position: relative;
  box-shadow: 0px 2px 12px rgb(0 0 0 / 12%);
  border-radius: 40px;
  width: 70vw;
  @media (max-width: 768px) {
    width: 100%;
  }
`;

const FormField = styled(FormTextField)`
  border: 1px solid #2557f3;
  border-radius: 40px;
  width: 100%;
  height: 48px;
  margin: 0 auto;
  padding-left: 51px;
  box-shadow: 0px 2px 12px rgb(0 0 0 / 12%);
   @media (max-width: 798px) {
    width: 100%;
   }
`;

const Close = styled.div`
  position: absolute;
  top: 15px;
  left: 21px;
  cursor: pointer;
  z-index: 60;
  width: 30px;
  height: 30px;
`;

const Submit = styled.button`
    border: none;
    outline: none;
    background: linear-gradient(180deg, #2557F3 0%, #2599F3 100%);
    position: absolute;
    top: 7px;
    right: 43px;
    width: 34px;
    height: 34px;
    border-radius: 50%;  
    cursor: pointer;
    transition: .8s;
    padding-top: 4px;
    &:hover {
      background: linear-gradient(180deg, #0E48FF 0%, #25CEF3 100%);
    }
`;

const Info = styled.div`
  position: absolute;
  top: 15px;
  right: 18px;
  cursor: pointer;
  svg {
    path {
      fill: #525559;
      transition: .3s
    }
  }
  &:hover {
    svg {
      path {
        fill: #73CCFE;
      }
    }
  }
`;

const OverLocal = styled(Overlay)`
  z-index: -1;
  background: transparent;
`;

const Delete = styled.div`
  position: absolute;
  top: 16px;
  right: 95px;
  cursor: pointer;
`;

const Over = styled(Overlay)`
  align-items: center;
  display: flex;
  justify-content: center;
  z-index: 50;
  background: rgba(255, 255, 255, 0.7);
  backdrop-filter: blur(4.5px);
  transition: opacity 0.5s;
`;

const Fade = styled.div`
  .fadein {
    -webkit-animation: display 0.3s linear forwards;
    animation: display 0.3s linear forwards;
  }
  
  @-webkit-keyframes display {
    0% {
      opacity: 0.2;
    }
    50% {
      opacity: 0.6;
    }
    100% {
      opacity: 1;
    }
  }
  
  @keyframes display {
    0% {
      opacity: 0.2;
    }
    50% {
      opacity: 0.6;
    }
    100% {
      opacity: 1;
    }
  }
  
 .fadeout{
  -webkit-animation: fadeinout 0.3s linear forwards;
  animation: fadeinout 0.3s linear forwards;
}

@-webkit-keyframes fadeinout {
  0% {
    opacity: 0.6;
  }
  50% {
    opacity: 0.2;
  }
  100% {
    opacity: 0;
  }
}

@keyframes fadeinout {
  0% {
    opacity: 0.6;
  }
  50% {
    opacity: 0.2;
  }
  100% {
    opacity: 0;
  }
}
  `;

const Error = styled(TextAccentSmall)`
  position: absolute;
  top: 35px;
  right: 0;
`;

export default function SearchInput({
  handleSearchOpenOff,
  searchOpen,
  id
}) {
  const { t: generalT } = useTranslation();

  const [text, setText] = useState("");
  const [info, setInfo] = useState(false);
  const [dialog, setDialog] = useState();
  const [sending, setSending] = useState(false);
  const [result, setResult] = useState();
  const [keys, setKeys] = useState([]);
  const [values, setValues] = useState();
  const [dialogMessage, setDialogMessage] = useState('');
  const [delay, setDelay] = useState(false);
  const [errorMessage, setErrorMessage] = useState();
  const [count, setCount] = useState();

  function handleFormField(e) {
    setText(e.target.value);
    setCount();
  }

  function handleInfo() {
    setInfo(!info);
  }

  async function fetchSearch() {
    setSending(true);
    try {
      const response = await network.get('api/leads/leads_ai_search', 
        { params: {ga_view_id: id, q: text, retry_count: count}});
      if (response.status === 200) {
        setResult(response.data.results);
        setCount(response.data.results.retry_count);
        setSending(false);
        if(response.data.results.type === 'table') {
          setKeys(Object.keys(Object.assign({}, ...response.data.results.result)));
          setValues(response.data.results.result.map(item => Object.values(item)));
        }
        return response;
      } else {
        setSending(false);
        throw new Error("Response status not 200");
      }
    } catch (error) {
      console.error(error); 
    }
  }

  function handleSubmit(e) {

    e.preventDefault();
    if(!delay) {
      setDialogMessage(text);
      setDialog(true);
      setDelay(true);
      fetchSearch();
    } else {
      setErrorMessage('Please, wait 3 seconds between questions.');
    }
  }


  useEffect(() => {
    if (delay) {
      const timer = setTimeout(() => {
        setDelay(false);
        setErrorMessage(false);
      }, 5000);

      return () => clearTimeout(timer);
    }
  }, [delay]);


  function handleInfoOff() {
    setInfo(false);
  }

  function handleDelete() {
    setDialog(false);
    setText("");
    setResult();
  }

  function handleDialogOff() {
    setDialog(false);
    setInfo(false);
  }

  function handleDialogOn() {
    if(result) {
      setDialog(true);
    } else {
      return;
    }
  }
 
  return (
    <Fade>
      <Over className={searchOpen ? "fadein" : "fadeout" }>
        <Wrapper>
          <FlexLogo>
            <Logo />
            <Block>
              <Text>
                <SearchIcon className="icon" />
                <p>{generalT("search")} </p>
                <Beta className="beta" />
              </Text>
            </Block>
          </FlexLogo>
          <Form onSubmit={handleSubmit}>
            <Close onClick={handleSearchOpenOff}>
              <CloseIcon />
            </Close>

            <FormField
              placeholder={generalT("askSearch")}
              value={text}
              onChange={handleFormField}
              autoFocus={true}
              onFocus={handleDialogOn}
             
            />
            {dialog && (
              <Delete onClick={handleDelete}>
                <DeleteIcon />
              </Delete>
            )}
            <Submit type="submit">    
              <SubmitIcon />
            </Submit>
            <Info onClick={handleInfo}>
              <InfoIcon />
            </Info>
            {info && (
              <>
                <OverLocal onClick={handleDialogOff}></OverLocal>
                <InfoBlock handleInfoOff={handleInfoOff} />
              </>
            )}
          </Form>
          {errorMessage && <Error>{errorMessage}</Error>}
          {dialog && (
            <>
              <OverLocal onClick={handleDialogOff}></OverLocal>
              <Dialog sending={sending} 
                text={dialogMessage} 
                string={true} 
                result={result} 
                values={values} 
                keys={keys} />
            </>
            
          )}
        </Wrapper>
      </Over>
    </Fade>
  );
}

SearchInput.propTypes = {
  handleSearchOpenOff: func,
  searchOpen: bool,
  id: number
};
