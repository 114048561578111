import { useTranslation } from 'react-i18next';
import { array, bool, func } from 'prop-types';
import { ReactComponent as Add } from 'assets/images/add.svg';
import { TextBlackThin } from 'base/styled';
import LeadQueue from './NewQueue/LeadQueue';
import styled from 'styled-components';

const Text = styled(TextBlackThin)`
&.notification {
  position: relative;
  &:after {
    content: '';
    display: block;
    position: absolute;
    right: -7px;
    top: -12px;
    width: 12px;
    height: 12px;
    background: #FF8947;
    border-radius: 50%;
  }
}
  @media (min-width: 768px) and (max-width: 1160px) {
    line-height: .6;
    margin-top: 7px;
   }
  @media (max-width: 768px) {
    white-space: nowrap;
    &.notification {
      &:after {
        top: -1px
      }
    }
  }
  @media (max-width: 1319px) {
     white-space: nowrap;
  }
`;

export default function AddQueue({setOpen, open, queues, gic,
  naics}) {
  const { t: homeT } = useTranslation('home');
  
  function handleOpen() {
    setOpen(!open);
  }

  return (
    <div className= "with-modal">
      <a
        /* eslint-disable */
        onClick={handleOpen}>
        <Add />
        <Text className={queues?.length > 0 ? 'notification' : ''}>{homeT('addQueue')}</Text>
      </a>
      <div className={open ? 'filtered' : 'd-none'}>
        <LeadQueue handleOpen={handleOpen} queues={queues} gic={gic} naics={naics}
         /* eslint-enable */
        />
      </div>
    </div>
  );
}

AddQueue.propTypes = {
  setOpen: func, 
  open: bool,
  queues: array,
  gic: array,
  naics: array,
};