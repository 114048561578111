import styled from 'styled-components';

const MainWrapper = styled.main`
  padding-top: 40px;  
  .main {
    padding: 0 12px 0 27px;
    @media (max-width: 768px) {
      padding: 0 10px; 
    }
  }
  &.impersonate {
    padding-top: 112px; 
    .filtered {
      top: 40px!important;
    }
    .side {
      padding-top: 47px
    }
    @media (max-width: 768px) {
      padding-top: 140px; 
    }
  }
  .impersonated {
    padding-top: 50px; 
  }
 
  @media (max-width: 768px) {
    padding-left: 0; 
  }
  @media (min-width: 769px) {
    .lefted {
      transition: .5s;
      margin-left: -60px;
    }
  }
  .detailed {
    .side {
      max-width: 60px;
    }
    .leads {
      right: -230px;
    }
    .company {
      top: 16%;
      right: -240px;
    }
  }
  .hiddenY {
    height: 70vh;
    overflow: hidden
  }
`;

export default MainWrapper;
