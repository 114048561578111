import { useState, useEffect, useContext } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { any, array, bool, func } from 'prop-types';
import { makeAsOptions } from 'base/utils';
import { fetchUsers } from 'store/slices/users';
import { useTranslation } from 'react-i18next';
import { v4 as uuidv4 } from 'uuid';
import styled from 'styled-components';
import {
  BlockMargin,
  FilledButton,
  FlexWrapperStart,
  FlexWrapperStartMiddle,
  FlexEnd,
  SecondaryBtnHigh,
  StyledSelect,
  StyledSelectSmall,
  TextAccentSmall,
  TextAccentThin
} from 'base/styled';
import FormTextField from 'base/components/FormTextField';
import { ReactComponent as Plus } from 'assets/images/plus.svg';
import { ReactComponent as Delete } from 'assets/images/delete.svg';
import { postQD, putQD } from 'store/slices/dashboardQueues';
import CascadQueue from './CascadQueue';
import Responsive from 'context/responsive';
import network from 'base/network';

const FlexWrapperStartMiddlePadding = styled(FlexWrapperStartMiddle)`
  padding-top: 40px;
  button {
    margin-right: 15px
  }
`;

const Wrapper = styled(FlexWrapperStartMiddle)`
  height: 55px;
  @media (max-width: 768px) {
    flex-direction: column;
    align-items: stretch;
    height: auto;
    margin: 10px 0;
    .bsfINW {
      width: 100%;
      min-width: auto;
    }
  }
`;

const BlockMarginSelect = styled(BlockMargin)`
  padding-top: 30px;
  .css-1rhbuit-multiValue {
    background: #C4C4C4;
    border-radius: 14px;
    padding: 2px;
    font-size: 14px;
  }
`;

const Button = styled.div`
  position: absolute;
  right: -25px;
  bottom: 0;
  cursor: pointer;
  width: 30px;
  height: 28px;
`;

const AddButton = styled.div`
 display: flex;
 padding-top: 30px;
 padding-right: 20px;
 cursor: pointer;
 position: relative;
 svg {
  margin: 15px 10px 0 0;
 }
 p {
  line-height: 1;
 }
`;

const CascadWrapper = styled.div`
  position: relative;
`;

const customStyles = {
  option: (provided, state) => ({
    ...provided,
    color: '#000000',
    fontSize: '14px',
    background: state.isSelected ? '#FFF9F9' : 'inherit',
    padding: 10,
  }),
};

const FlexWrap = styled(FlexWrapperStart)`
  @media (max-width: 768px) {
    flex-direction: column;
  }
`;

const Form = styled.form`
@media (max-width: 768px) {
  width: 100%;
  .equal {
    margin: 10px 0
  }
  .value {
    margin-bottom: 10px;
  }
}
.cascad:nth-child(2) .operator,
.cascad:nth-child(2) .delete {
  display: none;
}
#destroy {
  display: none;
}
.cascad {
  label {
    line-height: 16px;
  }
  .form-field {
    margin-top: 5px;
  }
}
.high {
  padding-top: 40px;
  max-width: 440px;
}
.width {
  max-width: 440px;
}
`;

function generateEmptyCriteria() {
  return {
    key: '',
    operator: '',
    value: '',
    join_operator: 'AND',
    multiple_filter: {
      path_value: '',
      time_value: '',
      count_value: '',
      path_operator: '',
      time_operator: '',
      count_operator: '',
      value_to: '',
      value_from: ''
    },
    _id: uuidv4()
  };
}

export default function LeadQueueForm({ handle,
  filterUserEdit,
  edit,
  gic,
  naics
}) {
  const { t: homeT } = useTranslation('home');
  const { users } = useSelector((state) => state.usersReducer);
  const [newc, setNew] = useState(false);
  const [filterName, setFilterName] = useState(
    filterUserEdit ? filterUserEdit.name : ''
  );
 
  const [criteria, setCriteria] = useState(
    edit ? filterUserEdit?.lead_filters_attributes : [generateEmptyCriteria()]
  );
 
  const [filterType, setFilterType] = useState([]);
  const [error, setError] = useState(''); 
  const { selectedId, ids } = useSelector((state) => state.gaViewIdReducer);
  const dispatch = useDispatch();
  const websitesOptions = makeAsOptions(ids, 'id', 'name');

  const [selectedWebsite, setSelectedWebsite] = useState(
    filterUserEdit
      ? websitesOptions.filter(option => {
        return filterUserEdit?.google_analytics_view_ids.some(item => item.id === option.value);})
      : selectedId
  );
  const [selectUser, setSelectUser] = useState(false);
  const [isSelectValue, setIsSelectValue] = useState(false);
  const [userList, setUserList] = useState();
  const ctx = useContext(Responsive);
  const { errors } = useSelector((state) => state.dashboardQueuesReducer);

  useEffect(() => {
    dispatch(fetchUsers({ users }));
    if(ctx.role !== 'admin') {
      network.get(`/api/account/users`)
        .then(({ data }) => {
          setUserList(makeAsOptions(data.results, 'id', 'full_name'));
        })
        .catch((error) => console.log(error));
    }
    /* eslint-disable */
  }, [dispatch]);
  /* eslint-enable */

  const usersOptions = makeAsOptions(users, 'id', 'full_name');
  const [filterUser, setFilterUser] = useState(
    filterUserEdit
      ? usersOptions.find((el) => el.value === filterUserEdit?.users[0]?.id)
      : ''
  );
  const [defaultUsers, setDefaultUsers] = useState(filterUserEdit ? filterUserEdit?.users?.map(u => {
    return {
      label: u.full_name,
      value: u.id
    };
  }) : null);
  const [changed, setChanged] = useState(false);
  const [newVisitor, setNewVisitor] = useState(false);
  const operatorOptions = [
    {value: 'OR', label: 'or'},
    {value: 'AND', label: 'and'},
  ];
  const [high, setHigh] = useState('');
  const equalsOptions = [
    { value: '=', label: 'equals' },
    { value: '!=', label: 'not equal to' },
    { value: 'contains', label: 'contains' },
    { value: 'does not contain', label: 'does not contain' }
  ];

  const [filterEqual, setFilterEqual] = useState(equalsOptions);

  const [websites, setWebsites] = useState();

  function handleWebsite(e) {
    setSelectedWebsite(e);
    setWebsites(Array.isArray(e) ? e.map(x => x.value) : []);
    console.log(websites);
  }

  useEffect(() => {
    dispatch(fetchUsers({ users }));
    if(filterUserEdit) {
      setDefaultUsers(filterUserEdit?.users?.map(u => {
        return {
          label: u.full_name,
          value: u.id
        };
      }));
    } else {
      setDefaultUsers(users?.map(u => {
        return {
          label: u.full_name,
          value: u.id
        };
      }));
    }
  /* eslint-disable */
  }, [dispatch]);
  /* eslint-enable */
 
  function filterNameHandler(e) {
    setFilterName(e.target.value);
  }

  function filterUserHandler(e) {
    setFilterUser(Array.isArray(e) ? e.map(x => x) : '');
    setChanged(true);
  }

  const [filterValue, setFilterValue] = useState(null);
  const [notAssigned, setNotAssigned] = useState(false);

  function filterValueHandler(e, item) {
    const newCriteria = [...criteria];
    const currentItemIndex = newCriteria.indexOf(item);
    if(isSelectValue) {
      newCriteria[currentItemIndex] = {
        ...newCriteria[currentItemIndex],
        value: (Array.isArray(e) ? e.map(x => x.value) : []).toString()
      };
      setCriteria(newCriteria);
      setFilterValue((Array.isArray(e) ? e.map(x => x.value) : []).toString());
    } else {
      newCriteria[currentItemIndex] = {
        ...newCriteria[currentItemIndex],
        value: e.target ? e.target.value : (Array.isArray(e) ? e.map(x => x.value) : []).toString()
      };
      setCriteria(newCriteria);
      setFilterValue(e.target ? e.target.value : (Array.isArray(e) ? e.map(x => x.value) : []).toString());
    }
  }

  function filterValueNaicsHandler(arr, item) {
    const newCriteria = [...criteria];
    const currentItemIndex = newCriteria.indexOf(item);
    newCriteria[currentItemIndex] = {
      ...newCriteria[currentItemIndex],
      value: arr
    };
    setCriteria(newCriteria);
    setFilterValue(arr);
  }

  function filterValueSelectHandler(e, item) {
    const newCriteria = [...criteria];
    const currentItemIndex = newCriteria.indexOf(item);
    newCriteria[currentItemIndex] = {
      ...newCriteria[currentItemIndex],
      value: !selectUser ? (Array.isArray(e) ? e.map(x => x.value) : []) : e.map(x => x.value).join() 
    };
    setCriteria(newCriteria);
    setFilterValue(Array.isArray(e) ? e.map(x => x.value) : []);
  }

  function filterTypeHandler(e, item) {
    const newCriteria = [...criteria];
    const currentItemIndex = newCriteria.indexOf(item);
    newCriteria[currentItemIndex] = {
      ...newCriteria[currentItemIndex],
      key: e.value
    };
    setCriteria(newCriteria);
    setFilterType(e.value);
  }

  function filterEqualHandler(e, item) { 
    const newCriteria = [...criteria];
    const currentItemIndex = newCriteria.indexOf(item);
    newCriteria[currentItemIndex] = {
      ...newCriteria[currentItemIndex],
      operator: e.value,
    };
    setCriteria(newCriteria);
    setFilterEqual(e.value);
  }

  function filterEqualNewVisitorHandler(e, item) {
    const newCriteria = [...criteria];
    const currentItemIndex = newCriteria.indexOf(item);
    newCriteria[currentItemIndex] = {
      ...newCriteria[currentItemIndex],
      operator: e.value,
      value: e.value
    };
    setCriteria(newCriteria);
    setFilterEqual(e.value);
  }

  function filterOperatorHandler(e, item) {
    const newCriteria = [...criteria];
    const currentItemIndex = newCriteria.indexOf(item);
    newCriteria[currentItemIndex] = {
      ...newCriteria[currentItemIndex],
      join_operator: e.value
    };
    setCriteria(newCriteria);
  }
  
  const [path, setPath] = useState();
  const [timeValue, setTimeValue] = useState();
  const [count, setCount] = useState();

  function MultiplePathHandler(e, item) {
    const newCriteria = [...criteria];
    const currentItemIndex = newCriteria.indexOf(item);
    newCriteria[currentItemIndex] = {
      ...newCriteria[currentItemIndex],
      multiple_filter: {
        ...newCriteria[currentItemIndex].multiple_filter,
        path_value: e.target.value
      },  
    };
    setCriteria(newCriteria);
    setPath(e.target.value);
  }

  function MultiplePathOperatorHandler(e, item) {
    const newCriteria = [...criteria];
    const currentItemIndex = newCriteria.indexOf(item);
    newCriteria[currentItemIndex] = {
      ...newCriteria[currentItemIndex],
      operator: '=',
      multiple_filter: {
        ...newCriteria[currentItemIndex].multiple_filter,
        path_operator: e.value
      },  
    };
    setCriteria(newCriteria);
  }

  function MultipleTimeHandler(e, item) {
    const newCriteria = [...criteria];
    const currentItemIndex = newCriteria.indexOf(item);
    newCriteria[currentItemIndex] = {
      ...newCriteria[currentItemIndex],
      multiple_filter: 
        {...newCriteria[currentItemIndex].multiple_filter,
          time_value: e.target.value
        },
    };
    setCriteria(newCriteria);
    setTimeValue(e.target.value);
  }

  function MultipleTimeOperatorHandler(e, item) {
    const newCriteria = [...criteria];
    const currentItemIndex = newCriteria.indexOf(item);
    newCriteria[currentItemIndex] = {
      ...newCriteria[currentItemIndex],
      operator: '=',
      multiple_filter: {
        ...newCriteria[currentItemIndex].multiple_filter,
        time_operator: e.value
      },  
    };
    setCriteria(newCriteria);
    //setPath(e.target.value);
  }

  function MultipleCountHandler(e, item) {
    const newCriteria = [...criteria];
    const currentItemIndex = newCriteria.indexOf(item);
    newCriteria[currentItemIndex] = {
      ...newCriteria[currentItemIndex],
      multiple_filter: {
        ...newCriteria[currentItemIndex].multiple_filter,
        count_value: e.target.value
      },
    };
    setCriteria(newCriteria);
    setCount(e.target.value);
  }

  function MultipleCountOperatorHandler(e, item) {
    const newCriteria = [...criteria];
    const currentItemIndex = newCriteria.indexOf(item);
    newCriteria[currentItemIndex] = {
      ...newCriteria[currentItemIndex],
      multiple_filter: {
        ...newCriteria[currentItemIndex].multiple_filter,
        count_operator: e.value
      },  
    };
    setCriteria(newCriteria);
  }

  function ValueFromHandler(e, item) {
    const newCriteria = [...criteria];
    const currentItemIndex = newCriteria.indexOf(item);
    newCriteria[currentItemIndex] = {
      ...newCriteria[currentItemIndex],
      multiple_filter: {
        ...newCriteria[currentItemIndex].multiple_filter,
        value_from: e.target.value
      },  
    };
    setCriteria(newCriteria);
  }
  
  function ValueToHandler(e, item) {
    const newCriteria = [...criteria];
    const currentItemIndex = newCriteria.indexOf(item);
    newCriteria[currentItemIndex] = {
      ...newCriteria[currentItemIndex],
      multiple_filter: {
        ...newCriteria[currentItemIndex].multiple_filter,
        value_to: e.target.value
      },  
    };
    setCriteria(newCriteria);
  }

  const [criteriaErrors, setCriteriaErrors] = useState('');

  function validateForm() {
    let isValid = true;

    // Validate filter name
    if (filterName.trim() === '') {
      setError('Please enter a name');
      isValid = false;
    } else if (!selectedId.value) {
      setError('Please choose a website');
      isValid = false;
    } else if (filterUser === '') {
      setError('Please choose users');
      isValid = false;
    } 

    const criteriaValidationErrors = criteria.map((item) => {
      const errors = {};
      const pattern = /(http:\/\/|https:\/\/|http:|https:|www\.)/g;
      if (item.key == "page_path" && pattern.test(item.value)) {
        errors.value = 'Please include page path without domain name, like "/contact_us", but not "https://abc.com/contact_us"';
        isValid = false;
        setHigh('width');
      }

      if (item.key.trim() === '') {
        errors.key = 'Please choose a filter type';
        isValid = false;
      }

      if (item.operator.trim() === '') {
        errors.operator = 'Please choose an operator';
        isValid = false;
      }

      if ( (item.key != "time_on_specific_page" && item.key != "views_count_of_specific_page") &&
        item.value.trim() === '' &&
        !['is null', 'is not null'].includes(item.operator.trim().toLowerCase())
      ) {
        errors.value = 'Please enter a value';
        isValid = false;
      }

      if (item.key == "time_on_specific_page") {
        if (item.multiple_filter.time_value === '' ) {
          errors.value = 'Please enter a time value';
          isValid = false;
        }
          
        if (item.multiple_filter.time_operator === '') {
          errors.value = 'Please enter a time operator';
          isValid = false;
        }
        if (item.multiple_filter.path_value === '' ) {
          errors.value = 'Please enter a path value';
          isValid = false;
        }

        if (pattern.test(item.multiple_filter.path_value)) {
          setHigh('high');
          errors.value = 'Please include page path without domain name, like "/contact_us", but not "https://abc.com/contact_us"';
          isValid = false;
        }
          
        if (item.multiple_filter.path_operator === '') {
          errors.value = 'Please enter an operator';
          isValid = false;
        }
      }

      if (item.key == "views_count_of_specific_page") {
        if (item.multiple_filter.count_value === '' ) {
          errors.value = 'Please enter a count value';
          isValid = false;
        }
          
        if (item.multiple_filter.count_operator === '') {
          errors.value = 'Please enter a count operator';
          isValid = false;
        }
        if (item.multiple_filter.path_value === '' ) {
          errors.value = 'Please enter a path value';
          isValid = false;
        }

        if (pattern.test(item.multiple_filter.path_value)) {
          setHigh('high');
          errors.value = 'Please include page path without domain name, like "/contact_us", but not "https://abc.com/contact_us"';
          isValid = false;
        }
      }

      return errors;
    });

    setCriteriaErrors(criteriaValidationErrors);

    return isValid;
  }

  function filterSubmit(e) {
    e.preventDefault();
    switch (true) {
    case filterName.length <= 0:
      setError('Please enter name');
      break;
    case !selectedId.value:
      setError('Please choose website');
      break;
    case filterUser === '':
      setError('Please choose users');
      break;
    case !filterType:
      setError('Please choose filter type');
      break;
    case !filterEqual:
      setError('Please choose filter equal');
      break;
    case filterValue && !newVisitor:
      setError('Please choose value');
      break;
    }
    
    if (validateForm() && !edit) { 
      const filterUserData = {
        name: filterName,
        automatic: true,
        google_analytics_view_id_ids: Array.isArray(selectedWebsite)
          ? selectedWebsite.map(item => item.value)
          : typeof selectedWebsite === 'object'
            ? [selectedWebsite.value]
            : [],
        user_ids: Array.isArray(filterUser) ? filterUser.map(e => e.value)
          : typeof filterUser === 'object'
            ? [filterUser.value]
            : [],
        lead_filters_attributes: criteria
      };
      dispatch(postQD({...filterUserData}));
      handle();
      if(!errors) {
        
        handle();
      } else if (errors) {
        setError(errors.error);
      }
     
    }
    if (validateForm() && edit) {
      const filterUserData = {
        name: filterName,
        automatic: true,
        // google_analytics_view_id_ids: [selectedWebsite.value],
        google_analytics_view_id_ids: Array.isArray(selectedWebsite)
          ? selectedWebsite.map(item => item.value)
          : typeof selectedWebsite === 'object'
            ? [selectedWebsite.value]
            : [],
        user_ids: changed ? filterUser.map(e => e.value) : [filterUser?.value],
        lead_filters_attributes: criteria
      };
      const Id = filterUserEdit.id;
      dispatch(putQD({id: Id,  body:filterUserData}));
      handle();
    }

  }

  const handleDelete = (item) => () => {
    const updatedCriteria = criteria.map(e =>
      e.id === item.id ? { ...e, _destroy: 'true' } : e
    );
    setCriteria(edit ? updatedCriteria : criteria.filter(e => e._id !== item._id));
  };
  
  function addFilterCriteria(e) {
    e.preventDefault();
    setCriteria(criteria.concat(generateEmptyCriteria()));
    setNew(true);
  }

  function handleOff() {
    handle();
    setError();
  }

  return (
    <Form onSubmit={filterSubmit}>
      <FlexWrap>
        <BlockMargin className='form-input'>
          <FormTextField
            type="text"
            onChange={filterNameHandler}
            value={filterName}
            label={homeT('filterName')}
          />
        </BlockMargin>
        <BlockMarginSelect className='form-field'>
          {
            <StyledSelect
              value={selectedWebsite}
              isMulti
              placeholder={homeT('website')}
              options={websitesOptions}
              onChange={handleWebsite}
              styles={customStyles}
              width="300px"
              components={{
                IndicatorSeparator: () => null,
                ClearIndicator: () => null
              }}
            />
          }
        </BlockMarginSelect>
        <BlockMarginSelect className='form-field'>
          {
            <StyledSelect
              isMulti
              placeholder={homeT('users')}
              defaultValue={ defaultUsers ? defaultUsers?.map(ele => ele) : ''} 
              options={userList || usersOptions}
              onChange={filterUserHandler}
              styles={customStyles}
              menuPlacement="bottom"
              components={{
                IndicatorSeparator: () => null,
                ClearIndicator: () => null
              }}
            />
          }
        </BlockMarginSelect>
      </FlexWrap>
      {criteria?.filter(e => !e._destroy)?.map((item, index) => (
        <CascadWrapper key={index} className="cascad" id={item._destroy == 'true' ? 'destroy' : ''}>
          <Wrapper className="operator">
            {
              <StyledSelectSmall
                placeholder={homeT('or')}
                options={operatorOptions}
                defaultValue={ operatorOptions.find((el) => el.value === item.join_operator) }
                onChange={(e) => filterOperatorHandler(e, item)}
                styles={customStyles}
                components={{
                  IndicatorSeparator: () => null,
                  ClearIndicator: () => null
                }}
              />
            }
          </Wrapper>
          <CascadQueue
            notAssigned={notAssigned}
            setNotAssigned={setNotAssigned}
            handlerType={filterTypeHandler}
            handlerEqual={filterEqualHandler}
            handlerEqualNewVisitor={filterEqualNewVisitorHandler}
            handlerValue={filterValueHandler}
            filterValueSelectHandler={filterValueSelectHandler}
            filterValue={filterValue}
            styles={customStyles}
            index={index}
            item={item}
            edit={edit}
            filterEqual={filterEqual} 
            users={userList || usersOptions}
            setSelectUser={setSelectUser}
            setIsSelectValue={setIsSelectValue}
            naicsHandler={filterValueNaicsHandler}
            gic={gic}
            naics={naics}
            newc={newc}
            setNewVisitor={setNewVisitor}
            PathHandler={MultiplePathHandler}
            pathOperator={MultiplePathOperatorHandler}
            timeOperator={MultipleTimeOperatorHandler}
            countOperator={MultipleCountOperatorHandler}
            path={path}
            TimeHandler={MultipleTimeHandler}
            timeValue={timeValue}
            CountHandler={MultipleCountHandler}
            count={count}
            ValueFromHandler={ValueFromHandler}
            ValueToHandler={ValueToHandler}
            setError={setError}
            queue={true}
          />
          <Button onClick={handleDelete(item)}>
            <Delete />
          </Button>
        </CascadWrapper>
      ))}
      {/* Criteria Validation Errors */}
      {criteriaErrors && criteriaErrors?.map((errors, index) => (
        <div key={index} className={high}>
          {errors.key && <TextAccentSmall className='relative'>{errors.key}</TextAccentSmall>}
          {errors.operator && (
            <TextAccentSmall className='relative'>{errors.operator}</TextAccentSmall>
          )}
          {errors.value && <TextAccentSmall className='relative'>
            {errors.value}</TextAccentSmall>}
        </div>
      ))}
      <FlexEnd>
        <TextAccentSmall className='relative' >{error}</TextAccentSmall>
        <AddButton className='addBtn' onClick={addFilterCriteria}>
          <Plus />
          <TextAccentThin>{homeT('addCriteria')}</TextAccentThin>
        </AddButton>
      </FlexEnd>
      <FlexWrapperStartMiddlePadding>
        <FilledButton type="submit">{homeT('saveQueue')}</FilledButton>
        <SecondaryBtnHigh type="reset" onClick={handleOff}>
          {homeT('cancel')}
        </SecondaryBtnHigh>
      </FlexWrapperStartMiddlePadding>
    </Form>
  );
}

LeadQueueForm.propTypes = {
  handle: func,
  filterUserEdit: any,
  edit: bool,
  item: any,
  gic: array,
  naics: array
};
