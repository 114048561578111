import { useContext, useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { fetchIntegrations } from 'store/slices/integrations';
import { useTranslation } from 'react-i18next';
import {
  Block,
  Loader,
  Modal,
  Overflow,
  Page,
  SearchForm,
  TextBlackSmall,
  TitleBlack,
  Tab,
  Flex,
  TextBlackThin,
  TitlePage
} from 'base/styled';
import styled from 'styled-components';
import { ReactComponent as SearchIcon } from 'assets/images/search.svg';
import { Row, Col } from '@bootstrap-styled/v4';
import Responsive from 'context/responsive';
import img from 'assets/images/warning.svg';
import SugarForm from './SugarForm';
import HubspotForm from './HubspotForm';

const Box = styled(Block)`
  margin: 17px;
  padding: 40px 20px 20px;
  text-align: center;
  flex-direction: column;
  height: 400px;
  position: relative;
  img {
    margin: auto;
  }
  a {
    text-decoration: none;
    font-weight: 500;
  }
  .text {
    max-width: 310px;
    overflow: hidden
  }
  @media (min-width: 769px) {
   width: 278px;
  }
  @media (max-width: 768px) {
    max-width: 230px;
    margin: 15px auto;
  }
`;

const IntegrationsPage = styled(Page)`
  width: 100%;
  `;

const Buttons = styled.div`
  display: flex;
  flex-direction: column;
  margin: auto auto 0;
  a {
    p {
      font-weight: 500;
    }
  }
  button {
    margin-bottom: 15px
  }
`;

const Api = styled.div`
  text-align: center;
  height: 140px;
  button {
    color: #000;
    font-family: Montserrat;
    font-size: 12px;
    font-weight: 400;
    border: none;
    border-radius: 13px;
    padding: 3px 22px;
    background: linear-gradient(92deg, #A9E0FF 1.64%, #EAF7FF 106.94%);
    box-shadow: 0px 1px 5px 0px rgba(87, 85, 85, 0.24);
  }
  .text {
    color: #85929B;
    font-size: 10px;
    font-weight: 400;
  }
`;

const Text = styled(TextBlackSmall)`
  font-weight: 400;
`;

const SearchBox = styled.div`
  padding-bottom: 40px;
  form {
    width: 100%;
  }
`;

const Title = styled(TitleBlack)`
  text-align: left;
  padding-left: 18px;
  width: 50%;
`;

const Outlined = styled.a`
  padding: 10px 16px;
  height: 37px;
  border-radius: 8px;
  font-family: Montserrat;
  font-size: 14px;
  color: #f96652;
  cursor: pointer;
  background-color: transparent;
  width: fit-content;
  margin: 0 auto;
  margin-bottom: 10px;
`;

const Accent = styled.a`
  font-family: inherit;
  font-size: 14px;
  padding: 6px 14px;
  font-size: .875rem;
  height: 30px;
  border-radius: 8px;
  border: 1px solid #F96652;
  color: #FEFDFD;
  cursor: pointer;
  background: #F96652;
  white-space: nowrap;
  width: fit-content;
  margin: 0 auto;
  margin-bottom: 10px;
}
`;

const Image = styled.div`
  width: 85px;
  height: 85px;
  margin: 0 auto;
  display: flex;
  align-items: center;
  img {
    width: 100%;
  }
`;

const Warning = styled.div`
  border-radius: 11.5px;
  padding: 12px 8px 12px 60px;
  width: 455px;
  color: #FEFDFD;
  background: rgba(50, 49, 49, 0.7) url(${img}) no-repeat 4% center;
  position: fixed;
  bottom: 50px;
  left: 38%;
  z-index: 9;
  font-size: .75rem;
  font-weight: 400;
  transition: .5s;
  a {
    text-decoration: underline;
    font-weight: 600;
    color: inherit;
  }
`;

const TextTitle = styled(Text)`
  height:32px;
`;

const Button = styled.a`
  background: #FFF3F3;
  color: #2C2C2C;
  border-radius: 14px;
  text-decoration: none;
  padding: 8px 16px;
  width: fit-content;
  margin: 0 auto;
  margin-bottom: 50px;
  font-size: .7rem;
  &.generate {
    margin: auto;
  }
`;

const Empty = styled.div`
  margin: 0 auto;
  display: flex;
  align-items: center;
  height: 190px;
  padding-top: 50px;
`;

const Tabses = styled.div`
  position: sticky;
  right: 7%;
  top: 40%;
  display: flex;
  flex-direction: column;
  margin-left: 20px
  @media (max-width: 768px) {
    position: static;
    flex-direction: row;
    flex-wrap: wrap;
  }
`;

const RowList = styled(Row)`
  ${(props) => (props?.active ? '' : 'display:none')}
  width: 98%;
  @media (min-width: 769px) {
    min-width: 600px;
  }
  @media (min-width: 1060px) {
    min-width: 910px;
  }
 
  @media (max-width: 768px) {
    min-width:auto;
    width: auto;
    margin: 0 auto;
  }
  min-width: 1000px;
`;

const TabInt = styled(Tab)`
  font-family: ${props => props.theme.fonts.main};
  border: none;
  border-radius: 23px;
  background-color: transparent;
  font-weight: ${(props) => (props?.active ? '600' : '400')};
  color: ${(props) => (props?.active ? props => props.theme.colors.accentRed  : '#2C2C2C')};
  text-align: left;
  transition: background-color 0.5s ease-in-out;
  @media (max-width: 768px) {
    width: fit-content;
    margin: 0 10px;
  }
`;

const Connected = styled.div`
  border-radius: 9px;
  background: #C5FFD1;
  color: #0CA449;
  font-size: 10px;
  font-weight: 400;
  padding: 3px 7px;
  position: absolute;
  top: 10px;
  left: 12px;
`;

const SecondaryBtnHigh = styled.a`
  font-family: inherit;
  padding: 6px 14px;
  font-size: 0.875rem;
  font-weight: 600;
  height: 32px;
  border-radius: 18px;
  border: none;
  color: rgb(254, 253, 253);
  cursor: pointer;
  background: rgb(59, 62, 69);
  white-space: nowrap;
`;

export default function Index() {
  const { t: rootT } = useTranslation('');
  const { t: integrationsT } = useTranslation('integrations');
  const [search] = useState(false);
  const [searchResults, setSearchResults] = useState();
  const [text, setText] = useState();
  const [open, setOpen] = useState(false);
  const [copied, setCopied] = useState(false);
  const dispatch = useDispatch();
  const [show, setShow] = useState(false);
  const { page, integrations, status } = useSelector(
    (state) => state.integrationsReducer
  );
  const [active, setActive] = useState(0);
  const [list, setList] = useState(false);
  const [enableModal, setEnableModal] = useState(false);

  const ctx = useContext(Responsive);

  function handleTab(e) {
    const index = parseInt(e.target.id, 0);
    if (index !== active) {
      setActive(index);
    }
  }

  useEffect(() => {
    dispatch(fetchIntegrations({ page, integrations }));
    setSearchResults(integrations);
    //setList(integrations);
    /* eslint-disable */
  }, [dispatch]);
  /* eslint-enable */

  useEffect(() => {
    if(list.length == 0) {
      setList(integrations);
    }
    /* eslint-disable */
  }, [integrations]);
  /* eslint-enable */

  useEffect(() => {
    setSearchResults(integrations);
    /* eslint-disable */
  }, [search]);
  /* eslint-enable */

  useEffect(() => {
    if(open) {
      setTimeout(() => {
        setOpen(false);
      }, 3000);
    }
  });

  function handleText(e) {
    setText(e.target.value);
    setSearchResults(integrations.filter((el) => {
      return el.title?.toLowerCase()?.includes(text?.toLowerCase());
    }));
  }

  function handleSubmit() {
    if(text) {
      setSearchResults(list.filter((el) => {
        return el.title.toLowerCase().includes(text?.toLowerCase());
      }));
    } else {
      setSearchResults(integrations);
    }
  }

  useEffect(() => {
    setList(text ? 
      searchResults : 
      (active === 0 && integrations) ||
      (active === 1 && integrations.filter(e => e.category.includes('native'))) ||
      (active === 2 && integrations.filter(e => e.category.includes('crm'))) ||
      (active === 3 && integrations.filter(e => e.category.includes('marketing'))) ||
      (active === 4 && integrations.filter(e => e.category.includes('sales'))) ||
      (active === 5 && integrations.filter(e => e.category.includes('communications'))) ||
      (active === 6 && integrations.filter(e => e.category.includes('productivity'))) ||
      (active === 7 && integrations.filter(e => e.category.includes('social'))) ||
      (active === 8 && integrations.filter(e => e.category.includes('other'))) ||
      (active === 9 && integrations.filter(e => e.category.includes('via_zapier')))
    );
    /* eslint-disable */
  }, [active, text])
   /* eslint-enable */

  function handleEnableWebsite() {
    setShow(true);
    setEnableModal(true);
  }

  function handleEnableWebsiteOff() {
    setShow(false);
    setEnableModal(false);
  }

  const handleCopy = (idx, text) => () => {
    const ta = document.createElement('textarea');
    ta.innerText = text;
    document.body.appendChild(ta);
    ta.select();
    document.execCommand('copy');
    ta.remove();
    setCopied((state) => ({
      ...state,
      [idx]: !copied[idx],
    }));
  };

  return (
    <IntegrationsPage>
      {status === 'loading' ? (
        <Loader />
      ) : (
        <>
          {open && (
            <Warning>
              {integrationsT('warning')}
              <a href="/company">upgrade</a>
              {integrationsT('warningPlan')}
            </Warning>
          )}
          {ctx.isMobile ? (
            <TitlePage>{integrationsT('title')}</TitlePage>
          ) : (
            <Title>{integrationsT('title')}</Title>
          )}
          <Flex>
            <div>
              <Row>
                <Col xs='12' md='8'></Col>
                <Col xs='12' md='3'>
                  <SearchBox>
                    <SearchForm onSubmit={e => e.preventDefault()}>
                      <SearchIcon onClick={handleSubmit} />
                      <input className='light' type="search" onChange={handleText} />
                    </SearchForm>
                  </SearchBox>
                </Col>
              </Row>
              {ctx.isMobile && (
                <Tabses>
                  <TabInt onClick={handleTab} active={active === 0} id={0}>All Integrations</TabInt>
                  <TabInt onClick={handleTab} active={active === 1} id={1}>Native</TabInt>
                  <TabInt onClick={handleTab} active={active === 2} id={2}>CRM</TabInt>
                  <TabInt onClick={handleTab} active={active === 3} id={3}>Marketing</TabInt>
                  <TabInt onClick={handleTab} active={active === 4} id={4}>Sales</TabInt>
                  <TabInt onClick={handleTab} active={active === 5} id={5}>Communications</TabInt>
                  <TabInt onClick={handleTab} active={active === 6} id={6}>Productivity</TabInt>
                  <TabInt onClick={handleTab} active={active === 7} id={7}>Social</TabInt>
                  <TabInt onClick={handleTab} active={active === 8} id={8}>Other</TabInt>
                  <TabInt onClick={handleTab} active={active === 9} id={9}>Via Zapier</TabInt>
                </Tabses>
              )}
              <RowList>
                {list && 
                  list.map((item, idx) => (
                    <Col xs="12" lg="4" md="6" key={idx}>
                      <Box>
                        {item.connected && <Connected>connected</Connected>}
                        <Image>
                          <img src={item.image} />
                        </Image>
                        <>
                          {item.api_key ? (
                            <>
                              <TextTitle>
                                {integrationsT('integrate')} {item.title}{' '}
                                {integrationsT('withVQ')}
                              </TextTitle>
                              <Api>
                                <button onClick={handleCopy(idx, item.api_key)}>{integrationsT('key')}</button>
                                <Text onClick={handleCopy(idx, item.api_key)} className="text">
                                  {copied[idx] ? 'copied!':'click to copy'}</Text>
                              </Api>
                            </>
                          ) : item.generate_api_url ? (
                            <Button className='generate' href={item.generate_api_url}>
                              {integrationsT('generate')}
                            </Button>
                          ) : (
                            <Empty>
                              <Text>
                                {integrationsT('integrate')} {item.title}{' '}
                                {integrationsT('withVQ')}
                              </Text>
                            </Empty>
                          )}
                        </>
                        <Buttons>
                          {item.title.includes('SugarCRM') && (
                            <Accent onClick={() => setShow(true)}>
                              Connect 
                            </Accent>
                          )}
                          {item?.change_preferences && item.connected && (
                            <Outlined href={item.change_preferences}>
                              {integrationsT('changePreferences')}
                            </Outlined>
                          )}
                          {item?.request_access && (
                            <Outlined href={item.request_access}>
                              {integrationsT('requestAccess')}
                            </Outlined>
                          )}
                          {!item.title.includes('SugarCRM') &&
                            (item.connected ? (
                              <Outlined href={item.disconnect_url}>
                                {integrationsT('disconnect')}
                              </Outlined>
                            ) : //<button onClick={handleDisConnect}>disconnect</button>
                              ctx.isLiteFree ? (
                                <SecondaryBtnHigh onClick={() => setOpen(true)}>
                                  {integrationsT('connect')}
                                </SecondaryBtnHigh>
                              ) : item.request_access ? (<></>) :
                                (
                                  <SecondaryBtnHigh target='blank' href={item.connect_url}>
                                    {integrationsT('connect')}
                                  </SecondaryBtnHigh>
                                ))}
                          {item.title.includes('Hubspot') && item.connected && (
                            <SecondaryBtnHigh onClick={handleEnableWebsite}>
                              {integrationsT('enableWebsites')}
                            </SecondaryBtnHigh>
                          )}
                          <a
                            href={item.documentation}
                            target="_blank"
                            rel="noreferrer"
                          >
                            <TextBlackThin>{rootT('learnMore')}</TextBlackThin>
                          </a>
                        </Buttons>
                      </Box>
                    </Col>
                  ))}
              </RowList>
            </div>
            {!ctx.isMobile && (
              <Tabses>
                <TabInt onClick={handleTab} active={active === 0} id={0}>All Integrations</TabInt>
                <TabInt onClick={handleTab} active={active === 1} id={1}>Native</TabInt>
                <TabInt onClick={handleTab} active={active === 2} id={2}>CRM</TabInt>
                <TabInt onClick={handleTab} active={active === 3} id={3}>Marketing</TabInt>
                <TabInt onClick={handleTab} active={active === 4} id={4}>Sales</TabInt>
                <TabInt onClick={handleTab} active={active === 5} id={5}>Communications</TabInt>
                <TabInt onClick={handleTab} active={active === 6} id={6}>Productivity</TabInt>
                <TabInt onClick={handleTab} active={active === 7} id={7}>Social</TabInt>
                <TabInt onClick={handleTab} active={active === 8} id={8}>Other</TabInt>
                <TabInt onClick={handleTab} active={active === 9} id={9}>Via Zapier</TabInt>
              </Tabses>
            )}
          </Flex>
        </>
      )}
      {show && (
        <>
          <Modal>
            <Overflow onClick={() => setShow(false)}></Overflow>
            <>
              {enableModal ? (
                <HubspotForm
                  handleFormOff={handleEnableWebsiteOff}
                /> 
              ) : (
                <SugarForm
                  handleFormOff={() => setShow(false)}
                /> 
              )}             
            </>
          </Modal>
        </>
      )}
    </IntegrationsPage>
  );
}
