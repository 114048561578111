import {
  Check, Flex
} from 'base/styled';
import { useTranslation } from 'react-i18next';
import styled from 'styled-components';
import link from 'assets/images/link_grey.svg';
import { any, bool, func, number, string } from 'prop-types';

export default function PaidPersonalization({form, personalisations, setPersonalisations, cur, add, idx}) {
  const { t: companyT } = useTranslation('company');

  const PaidBlock = styled.div`
  background: linear-gradient(280deg, #F0FFE7 -3.1%, #C9FF9F 117.63%);
  border-radius: 8px;
  padding: 15px;
  color: #2C2C2C;
  font-size: ${form ? '10px;' : '14px;' }
  position: relative;
  margin-bottom: 10px;
  p {
    padding-left: 15px;
    line-height: ${form ? '14px;' : '22px;' }
    margin: 0;
    white-space: normal!important;
  }
  .bold {
    font-weight: 600;
  }
  `;

  const Link = styled.a`
  background: url(${link}) no-repeat left 1% / 14px;
  display: block;
  text-decoration: none;
  color: #85929B;
  font-size: 12px;
  font-weight: 600;
  margin: ${form ? '20px 0 0 35px;' : '20px 0 0 35px;'}
  padding-left: 23px;
  `;

  const CheckGreen = styled(Check)`
  height: 55%!important;
  &:checked + span {
    border: 1px solid #3EC632;
  }
  & + span {
    width: ${form ? '16px;' : '16px;' }
    height: 16px;
    top: ${form ? '5px;' : '31px;' }
    left: 0px;
    border: 1px solid #3EC632;
    background: #fff;
  }
  &:checked + span:after {
    background: #3EC632;
    width: 10px;
    height: 10px;
    top: 2px;
    left: 2px;
    border: 1px solid #3EC632;
  }
`;

  // function handlePaid(idx) {
  //   console.log(idx)
  //   setPersonalisations(!personalisations);
  // }

  const handlePaid = (idx) => () => {
    setPersonalisations((state) => ({
      ...state,
      [idx]: !personalisations[idx],
    }));
  };

  function handlePaidForm() {
    setPersonalisations(!personalisations);
  }

  return (
    <PaidBlock>
      <Flex>
        <CheckGreen onChange={form ? handlePaidForm : handlePaid(idx)} 
          checked={form ? personalisations : personalisations[idx]}></CheckGreen>
        <span></span>
        <div>
          <p>{companyT('2x')}</p>
          <p>{companyT('addWebsite')}</p>
          <p>
            {companyT('price')}
            {add && add != isNaN ? <span className='bold'>
              {cur === 'USD' && '$' ||
              cur === 'CAD' && '$' ||
              cur === 'EUR' && '€' ||
              cur === 'GBP' && '£'}
              { Math.floor(add) }{' '}
            </span> : '$200 '}
            {companyT('more')}
          </p>
        </div>
      </Flex>
      <Link href='https://www.visitorqueue.com/website-personalization' target='blank'>{companyT('about')}</Link>
    </PaidBlock>
  );
}

PaidPersonalization.propTypes = {
  form: bool,
  personalisations: any,
  setPersonalisations: func, 
  cur: string, 
  add: any,
  idx: number
};
