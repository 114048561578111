import styled from 'styled-components';

const MenuItem = styled.li`
  height: 52px;
  border-top-right-radius: 26px;
  border-bottom-right-radius: 26px;
  background-color: transparent;
  display: flex;
  align-items: center;
  padding-left: 15px;

  font-weight: ${ props => props.$active ? '600' : '400'};
  font-size: 12px; 
  transition: 0.2s all;
  &.d-none {
    display: none
  }
  svg {
    margin-right: 10px;
  }
  &.integrations {
    a .active {
      svg {
        margin-left: 2px;
        margin-top: -2px
      }
    }
  }
`;

export default MenuItem;
