import { useEffect, useState } from 'react';
import { any, func } from 'prop-types';
import CreatableSelect from 'react-select/creatable';
import { useDispatch } from 'react-redux';
import { fetchLeadById } from 'store/slices/leads';
import {
  AccentButton,
  ButtonsWrap,
  SecondaryBtnHigh,
  TextBlackBig,
  TextBlackThin,
} from 'base/styled';
import styled from 'styled-components';
import { useTranslation } from 'react-i18next';
import network from 'base/network';
import img from 'assets/images/close.svg';

const Content = styled.div`
  padding: 10px;
  min-width: 330px;
  max-width: 330px;
  button {
    margin-top: 20px;
  }
  .Select-value{
    border: 1px solid darkgray !important;
  }
  
  .Select-value-icon {
    display: none !important;
  }

  .react-select__multi-value__remove {
	display: none !important;
}
  .Select-clear-zone {
    display: none !important;
    }
  .css-1y0h00e-Control {
    .css-g1d714-ValueContainer {
      flex-direction: column;
      align-items: start;
      .css-1rhbuit-multiValue {
        background: #C6D5DD;
        border-radius: 14px;
        margin: 3px;
        padding: 0 5px;
        .css-xb97g8 {
          background: url(${img}) no-repeat center / 12px;
          width: 20px;
          svg {
          display: none;
          }
        }
      }
    }
  }
`;

const customStyles = {
  control: () => ({
    borderRadius: '8px',
    fontSize: '.75em',
    border: '1px solid #d1d1d1',
    display: 'flex',
    marginTop: '30px'
  }),
  menu: () => ({
    padding: 0,
    fontSize: '.75em',
    fontWeight: '600',
    overflowX: 'hidden',
    marginLeft: '0',
    textAlign: 'left',
    border: '1px solid #d1d1d1',
    borderRadius: '8px',
    display: 'inline-block',
    '@media only screen and (min-width: 1200px)': {
      width: '100%',
    },
  }),
  option: (base, state) => ({
    ...base,
    color: '#000000',
    fontSize: '12px',
    padding: 10,
    backgroundColor: state.isSelected ? '#FFF3F3' : '',
    ':active': {
      backgroundColor: state.isSelected ? '' : '#FFF3F3'
    }
  }),
  multiValueRemove: (style, state) => {
    console.log('multi value remove', state, style);
    return {
      ...style,
      ...(state.data?.isDisabled
        ? {
          visibility: 'hidden',
          width: '4px',
        }
        : {}),
    };
  },

};

export default function Tags({handleShowModalOff, leadsMarked, setResult}) {
  const { t: popupT } = useTranslation('popup');
  const { t: rootT } = useTranslation('');

  const [tags, setTag] = useState();
  const [list, setList] = useState([]);
  const dispatch = useDispatch();
  
  function tagHandler(e) {
    setTag(Array.isArray(e) ? e.map(x => x.value) : []);
  }

  useEffect(()=> {
    //fetchAllDetails();
    network.get('/api/account/tags', {})
      .then(data => {
        console.log(data.data.results);
        setList(data.data.results.map(e => (
          {value:e, label:e}
        )));
      }
      );

  }, []);

  function handleSubmit() {
    network.post('/api/leads/lead_tags', {
      tags: tags.join(),
      selected_ids: leadsMarked.join(),
    })
      .then(data => {
        setResult(popupT('tagged'));
        handleShowModalOff();
        leadsMarked.forEach(element => {
          dispatch(fetchLeadById(element));
        });
        return data;
      });
  }

  return (
    <form>
      <TextBlackBig style={{textAlign: "center"}}>{rootT('tags')}</TextBlackBig>
      <Content>
        <TextBlackThin>{popupT('tagTo')}</TextBlackThin>
        <CreatableSelect
          isMulti
          options={list}
          onChange={tagHandler}
          styles={customStyles}
        />
        <ButtonsWrap>
          <SecondaryBtnHigh onClick={handleShowModalOff}>
            {rootT('cancel')}
          </SecondaryBtnHigh>
          <AccentButton onClick={handleSubmit}>{rootT('save')}</AccentButton>
        </ButtonsWrap>
      </Content>
    </form>
  );
}

Tags.propTypes = {
  handleShowModalOff: func,
  handleSuccess: func,
  leadsMarked: any,
  setResult: func
};
