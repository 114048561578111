import { useState, useEffect, useContext } from 'react';
import { CookiesProvider } from 'react-cookie';
import { Provider } from 'react-redux';
import { BrowserRouter } from 'react-router-dom';
import { HelmetProvider } from 'react-helmet-async';
import RouterConfig from 'navigation/RouterConfig';
import { ThemeProvider } from 'styled-components';
import { I18nextProvider } from 'react-i18next';
import i18n from './i18n';
import store from 'store';
import theme from 'base/theme';
import MainWrapper from 'base/styled/MainWrapper';
import HeadContent from 'base/components/HeadContent';
import Responsive from 'context/responsive';
import Terms from 'base/components/Terms';
import ErrorBoundary from 'base/components/ErrorBoundary';
import MetaTags from 'base/components/Metatags';
function App() {
  
  const ctx = useContext(Responsive);
  const [detail, setDetails] = useState(false);
  const [hitLimit, setHitLimit] = useState(ctx.hitLimit);
  const [navbar, setNavbar] = useState(false);
  const [impersonate, setImpersonate] = useState(false);
  const [isImpersonate, setIsImpersonate] = useState(false);
  const [width, setWidth] = useState(window.innerWidth);
  const [isTrial, setIsTrial] = useState(false);
  const [sunset, setSunset] = useState(false);
  const [isLiteFree, setIsLiteFree] = useState(false);
  const [role, setRole] = useState(null);
  const [active, setActive] = useState(true);
  const [showNewConversion, setShowNewConversion] = useState(false);
  const [hidden, setHidden] = useState(false);

  function handleWindowSizeChange() {
    setWidth(window.innerWidth);
  }

  useEffect(() => {
    let impersonated = window.localStorage.getItem('IMPERSONATE');
    let user = JSON.parse(window.localStorage.getItem('USER'));
    window.addEventListener('resize', handleWindowSizeChange);
    setIsImpersonate(impersonated);
    setImpersonate(user);
    ctx.setIsPersonate(impersonated);
    //ctx.setImpersonate(impersonated);
   
    return () => {
      window.removeEventListener('resize', handleWindowSizeChange);
    };
    /* eslint-disable */
  }, [isImpersonate]);
    /* eslint-enable */
  let stor = store.getState().accountReducer; 
  useEffect(() => {   
    setHitLimit(ctx.hitLimit);
    setIsLiteFree(store.getState().accountReducer.subscription.name == "Free lite plan");
    ctx.setIsLiteFree(store.getState().accountReducer.subscription.name == "Free lite plan");
    const diff = Date.parse(store.getState().accountReducer.details?.trial_end_date) - Date.now();
    setIsTrial(diff > 0 ? store.getState().accountReducer.details?.trial_end_date : false);
  }, [ctx, stor]);

  const isMobile = width <= 768;
  const isTablet = width <= 1100;

  useEffect(() => {
    const handleBeforeUnload = (event) => {
      event.preventDefault();
      // Custom logic to handle the refresh
      // Display a confirmation message or perform necessary actions
    };
    window.addEventListener('beforeunload', handleBeforeUnload);
    return () => {
      window.removeEventListener('beforeunload', handleBeforeUnload);
    };
  }, []);

  
  return (
    <ErrorBoundary>
      <I18nextProvider i18n={i18n}>
        <HelmetProvider>
          <CookiesProvider>
            <ThemeProvider theme={theme}>
              <BrowserRouter>
                <Provider store={store}>
                  <Responsive.Provider
                    value={{
                      isMobile: isMobile,
                      isTablet: isTablet,
                      details: detail,
                      impersonate: impersonate,
                      setImpersonate: setImpersonate,
                      setDetails: setDetails,
                      isImpersonate: isImpersonate,
                      setIsPersonate: setIsImpersonate,
                      navbar: navbar,
                      setNavbar: setNavbar,
                      isTrial: isTrial,
                      setIsTrial: setIsTrial,
                      sunset: sunset,
                      setSunset: setSunset,
                      isLiteFree: isLiteFree,
                      setIsLiteFree: setIsLiteFree,
                      role: role,
                      setRole: setRole,
                      hitLimit: hitLimit,
                      setHitLimit: setHitLimit,
                      active: active,
                      setActive: setActive,
                      showNewConversion: showNewConversion,
                      setShowNewConversion: setShowNewConversion
                    }}
                  >
                    <MetaTags />
                    <MainWrapper
                      className={
                        ctx.isImpersonate || isLiteFree || isTrial || hitLimit
                          ? 'impersonate'
                          : ''
                      }
                    >
                      <HeadContent impersonate={impersonate} setHidden={setHidden}/>
                      <RouterConfig hidden={hidden}/>
                      <Terms />
                    </MainWrapper>
                  </Responsive.Provider>
                </Provider>
              </BrowserRouter>
            </ThemeProvider>
          </CookiesProvider>
        </HelmetProvider>
      </I18nextProvider>
    </ErrorBoundary>
  );
}

export default App;
